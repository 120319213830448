import React from 'react';

import { Txt } from '@vst/beam';

import { CheckoutCartProps, DurationPromo } from '../types';

import styles from './priceDetailPromo.module.scss';

interface Props {
  phraseKey: CheckoutCartProps['phraseKeys']['serviceDetails']['priceAfterPromotion'];
  durationClosingOffer: number;
  promoDuration: DurationPromo;
  totalPrice: string;
  totalPriceWithoutPromotions: string;
  totalPriceWithAddons?: string;
}

const formatPhrase = (phrase: string, duration: number) => {
  return duration > 1 ? phrase : phrase.slice(0, -1);
};

const generateDisplayText = ({
  phraseKey,
  durationClosingOffer,
  promoDuration = { planPromoDuration: undefined, addonPromoDuration: 0 },
  totalPrice,
  totalPriceWithoutPromotions,
  totalPriceWithAddons,
}: Props) => {
  const { planPromoDuration, addonPromoDuration } = promoDuration;
  if (!planPromoDuration && !addonPromoDuration && !durationClosingOffer) {
    return '';
  }

  const promotions = new Map<number, string>();

  if (durationClosingOffer) {
    promotions.set(
      durationClosingOffer,
      formatPhrase(phraseKey?.(`${totalPrice}`, `${durationClosingOffer}`), durationClosingOffer),
    );
  }

  if (promoDuration.planPromoDuration && totalPriceWithoutPromotions) {
    promotions.set(
      promoDuration.planPromoDuration,
      formatPhrase(
        phraseKey?.(`${totalPriceWithoutPromotions}`, `${promoDuration.planPromoDuration}`),
        promoDuration.planPromoDuration,
      ),
    );
  }

  if (promoDuration.addonPromoDuration && totalPriceWithAddons) {
    promotions.set(
      promoDuration.addonPromoDuration,
      formatPhrase(
        phraseKey?.(`${totalPriceWithAddons}`, `${promoDuration.addonPromoDuration}`),
        promoDuration.addonPromoDuration,
      ),
    );
  }

  return Array.from(promotions.entries())
    .sort((a, b) => a[0] - b[0])
    .map(([, text]) => text)
    .join(', ');
};

const PriceDetailPromo: React.FC<Props> = (props) => {
  const displayText = generateDisplayText(props);

  return (
    <Txt
      variant="tinyItalic"
      color="info"
      className={styles['priceDetailPromo']}
      data-testid="totalMonthlyPlanPriceWithoutPromo"
    >
      {displayText}
    </Txt>
  );
};

export default PriceDetailPromo;
