import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Icon, Row, Spacer, StandAloneLink, Surface, Tooltip, Txt } from '@vst/beam';
import { Autorenew, Info } from '@vst/beam-icons/icons';

import { checkForLength, priceDecimalFormat, splitText } from '@buy-viasat/utils';

import { SkeletonLoader } from '../../SkeletonLoader';
import { useTrackEvent } from '../../../hooks/analytics';

import { navActions, Routes } from '@buy-viasat/redux/src/navigator';
import { selectLocale, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';
import { planActions, selectPlanId, selectPlanName } from '@buy-viasat/redux/src/plan';

import { Maybe } from '@buy-viasat/types/build/bv';
import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';

import { CheckoutCartClosingOffers } from '../CheckoutCartClosingOffers';
import PriceDetailPromo from '../PriceDetailPromo';
import { calculationData, CheckoutCartProps } from '../types';
import styles from './checkoutCartServiceDetails.module.scss';

export type CheckoutCartServiceDetailsProps = {
  name: string;
  phraseKeys: CheckoutCartProps['phraseKeys']['serviceDetails'];
  data: calculationData;
};

export const CheckoutCartServiceDetails = ({
  name,
  data: {
    cartItems,
    shouldDisplayClosingOffer,
    durationClosingOffer,
    price,
    priceWithPromo,
    totalPrice,
    totalPriceWithPromo,
    totalPriceWithoutPromotions,
    isBackgroundAPICallLoading,
  },
  phraseKeys: { changeButton, free: promotionLabel, categoryName, priceAfterPromotion },
}: CheckoutCartServiceDetailsProps) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const planId = useSelector(selectPlanId);
  const planName = useSelector(selectPlanName);
  const locale = useSelector(selectLocale);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);

  const changeProductFromCart =
    (name: Maybe<string> | undefined, id: Maybe<string> | undefined) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const params = { label: id, property: name ?? undefined };
      trackEvent(TrackingCategory.CART, TrackingActions.EDITED_PLAN, params);
      dispatch(navActions.routeUserTo(Routes.PLAN));
      dispatch(planActions.setHavePlansLoaded(false));
    };

  const {
    promo,
    leaseFeeShortName,
    leaseFeePrice,
    contractTermsDisclosure: disclosureText,
    contractTerms,
  } = cartItems?.plan || {};

  const leaseFeeShortNameFormatted = splitText(String(leaseFeeShortName));

  const shouldUsePromotionLabel = priceWithPromo === 0;
  const isPlanFree = price === 0;

  const contractTermsText = splitText(String(contractTerms));

  return (
    <Container fluid mt="24px" className={styles['container']}>
      <Row edge2edge>
        <Col className={styles['column']}>
          <Surface variant="secondary" p="16px" data-testid="ServiceDetailsContainer" radius="8px">
            <Surface variant="secondary" className={styles['headline']} data-testid="ServiceDetailsHeadline" pb="4px">
              <Txt variant="heading6" data-testid="ServiceDetailsHeadlineTitle">
                {categoryName}
              </Txt>
              <StandAloneLink
                href="#"
                linkSize="small"
                icon={Autorenew}
                className={styles['changeOrRenew']}
                onClick={changeProductFromCart(planId, planName)}
              >
                {changeButton}
              </StandAloneLink>
            </Surface>
            <Surface variant="secondary" className={styles['planNameAndPrice']} data-testid="planNameAndPriceContainer">
              <Surface variant="secondary" className={styles['planTitle']} data-testid="planTitleSurface">
                <Txt variant="bodySmallRegular" data-testid="planTitle" className={styles['planName']}>
                  {isBackgroundAPICallLoading ? <SkeletonLoader characters={15} height={'sm'} /> : name}
                </Txt>
                <Txt
                  variant="tinyItalic"
                  className={styles['contractDuration']}
                  data-testid="planTitleContractDuration"
                >
                  {isBackgroundAPICallLoading ? (
                    <Spacer y="4px">
                      <SkeletonLoader characters={15} height={'sm'} />
                    </Spacer>
                  ) : checkForLength(String(contractTerms)) ? (
                    contractTermsText.firstHalf
                  ) : (
                    contractTerms
                  )}
                  {disclosureText && (
                    <Tooltip variant="simple" bodyText={disclosureText}>
                      <Icon icon={Info} size="16px" color="gray_600" ml="4px" />
                    </Tooltip>
                  )}
                </Txt>
                {checkForLength(String(contractTerms)) && (
                  <Txt
                    variant="tinyItalic"
                    className={styles['contractDuration']}
                    data-testid="planTitleContractDuration"
                  >
                    {contractTermsText.secondHalf}
                  </Txt>
                )}
              </Surface>
              {isBackgroundAPICallLoading ? (
                <SkeletonLoader characters={5} height={'sm'} />
              ) : (
                <Surface
                  variant="secondary"
                  className={promo ? styles['planPrice'] : styles['noPromo']}
                  data-testid="planPriceSurface"
                >
                  {promo || isPlanFree ? (
                    <Txt variant="bodySmallBold" color="success" data-testid="monthlyPlanPriceWithPromo">
                      {shouldUsePromotionLabel || isPlanFree
                        ? promotionLabel
                        : `${priceDecimalFormat(priceWithPromo, locale, currencySymbol)}`}
                    </Txt>
                  ) : (
                    <Txt
                      variant={promo ? 'bodySmallItalic' : 'bodySmallRegular'}
                      className={promo ? styles['strikethrough'] : ''}
                      color={leaseFeePrice ? 'subtle' : 'regular'}
                      data-testid="monthlyPlanPriceWithoutPromo"
                    >
                      {priceDecimalFormat(price, locale, currencySymbol)}
                    </Txt>
                  )}
                </Surface>
              )}
            </Surface>
            <CheckoutCartClosingOffers
              shouldDisplayClosingOffer={shouldDisplayClosingOffer}
              isBackgroundAPICallLoading={isBackgroundAPICallLoading}
            />
            {isBackgroundAPICallLoading ? (
              <Surface variant="secondary" className={styles['equipmentFee']} pt={'4px'} pb={'4px'}>
                <SkeletonLoader characters={14} height={'sm'} />
                <SkeletonLoader characters={5} height={'sm'} />
              </Surface>
            ) : (
              leaseFeePrice && (
                <Surface
                  variant="secondary"
                  className={styles['equipmentFee']}
                  data-testid="equipmentFeeSurface"
                  pb="8px"
                >
                  <Surface variant="secondary" className={styles['leaseFeeName']}>
                    <Txt variant="bodySmallRegular" data-testid="equipmentFeeText">
                      {leaseFeeShortNameFormatted.firstHalf}
                    </Txt>
                    <Txt variant="bodySmallRegular" data-testid="equipmentFeeText">
                      {leaseFeeShortNameFormatted.secondHalf}
                    </Txt>
                  </Surface>
                  <Txt variant="bodySmallRegular" data-testid="equipmentFeePrice" color="subtle">
                    {priceDecimalFormat(leaseFeePrice, locale, currencySymbol)}
                  </Txt>
                </Surface>
              )
            )}
            {isBackgroundAPICallLoading ? (
              <Spacer className={styles['skeleton']}>
                <SkeletonLoader characters={5} height={'sm'} />
                <SkeletonLoader characters={14} height={'sm'} />
              </Spacer>
            ) : (
              (promo || leaseFeePrice) && (
                <Surface
                  variant="secondary"
                  className={styles['totalPlanPriceSurface']}
                  data-testid="totalPlanPriceSurface"
                  pt="4px"
                >
                  {promo || shouldDisplayClosingOffer ? (
                    <>
                      <Txt
                        variant="bodySmallBold"
                        className={styles['totalPlanPrice']}
                        data-testid="totalMonthlyPlanPriceWithPromo"
                      >
                        {priceDecimalFormat(totalPriceWithPromo, locale, currencySymbol)}
                      </Txt>
                      <PriceDetailPromo
                        phraseKey={priceAfterPromotion}
                        durationClosingOffer={durationClosingOffer}
                        promoDuration={{
                          planPromoDuration: promo?.duration,
                        }}
                        totalPrice={priceDecimalFormat(totalPrice, locale, currencySymbol)}
                        totalPriceWithoutPromotions={priceDecimalFormat(
                          totalPriceWithoutPromotions,
                          locale,
                          currencySymbol,
                        )}
                      ></PriceDetailPromo>
                    </>
                  ) : (
                    <Txt variant="bodySmallBold" className={styles['totalPlanPrice']}>
                      {priceDecimalFormat(totalPriceWithPromo, locale, currencySymbol)}
                    </Txt>
                  )}
                </Surface>
              )
            )}
          </Surface>
        </Col>
      </Row>
    </Container>
  );
};
