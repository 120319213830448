import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { routesIntlMapping } from '../navigator';
import { RootState } from '../types';
import { AppState, Features, initialAppState } from './types';
import { Country } from '@buy-viasat/types/build/bv';

export const toPascalCase = (stringToConvert: string): string => {
  const routeAlias = routesIntlMapping[stringToConvert] || stringToConvert;
  return `${routeAlias.charAt(0).toUpperCase()}${routeAlias.slice(1)}`;
};

export const selectAppDomain = (state: RootState): AppState => {
  return state?.app || initialAppState;
};

export const selectLoadedFeatureFlags = createSelector(
  [selectAppDomain],
  (state: AppState) => state.loadedFeatureFlags,
);
export const selectFeatureFlagsUserKey = createSelector(
  [selectAppDomain],
  (state: AppState) => state.featureFlagsUserKey,
);
export const selectFeatureFlags = createSelector([selectAppDomain], (state: AppState) => state.featureFlags);
export const useFeatureFlags = (): Features => useSelector(selectFeatureFlags);

export const selectIsVs2LargePlan = createSelector(
  [selectAppDomain],
  (state: AppState) => state.country !== Country.IT && state.country !== Country.BR,
);
export const selectBillingContactEqualsAccountHolder = createSelector(
  [selectAppDomain],
  (state: AppState) => state.billingContactEqualsAccountHolder,
);
export const selectOnSiteContactEqualsAccountHolder = createSelector(
  [selectAppDomain],
  (state: AppState) => state.onSiteContactEqualsAccountHolder,
);
export const selectCreditCheckResults = createSelector(
  [selectAppDomain],
  (state: AppState) => state.creditCheckResults,
);
export const selectCurrentModal = createSelector([selectAppDomain], (state: AppState) => state.currentModal);
export const selectLocale = createSelector([selectAppDomain], (state: AppState) => state.locale);
export const selectCustomerType = createSelector([selectAppDomain], (state: AppState) => state.customerType);
export const selectCountry = createSelector([selectAppDomain], (state: AppState) => state.country);
export const selectIsAvailable = createSelector([selectAppDomain], (state: AppState) => state.isAvailable);
export const selectIsCartVisible = createSelector([selectAppDomain], (state: AppState) => state.isCartVisible);
export const selectIsCheckoutButtonDisabled = createSelector(
  [selectAppDomain],
  (state: AppState) => state.isCheckoutButtonDisabled,
);
export const selectIsModalVisible = createSelector([selectAppDomain], (state: AppState) => state.isModalVisible);
export const selectIsOrderSubmitting = createSelector([selectAppDomain], (state: AppState) => state.isOrderSubmitting);
export const selectIsPaymentInformationComplete = createSelector(
  [selectAppDomain],
  (state: AppState) => state.isPaymentInformationComplete,
);
export const selectIsPersonalInformationComplete = createSelector(
  [selectAppDomain],
  (state: AppState) => state.isPersonalInformationComplete,
);
export const selectIsScheduleInstallationComplete = createSelector(
  [selectAppDomain],
  (state: AppState) => state.isScheduleInstallationComplete,
);
export const selectIsTaxCodesLoading = createSelector([selectAppDomain], (state) => state.isTaxCodesLoading);
export const selectLocation = createSelector([selectAppDomain], (state) => state.location);
export const selectPartyId = createSelector([selectAppDomain], (state: AppState) => state.partyId);
export const selectOrderId = createSelector([selectAppDomain], (state: AppState) => state.orderId);
export const selectRelnId = createSelector([selectAppDomain], (state: AppState) => state.relnId);
export const selectRedirected = createSelector([selectAppDomain], (state: AppState) => state.redirected);
export const selectRouteName = createSelector([selectAppDomain], (state: AppState) => toPascalCase(state.currentRoute));
export const selectServiceabilityError = createSelector(
  [selectAppDomain],
  (state: AppState) => state.serviceabilityError,
);
export const selectStartTime = createSelector([selectAppDomain], (state: AppState) => new Date(state.startTime));
export const selectUrlParams = createSelector([selectAppDomain], (state: AppState) => state.urlParams);
export const selectWorkOrderId = createSelector([selectAppDomain], (state: AppState) => state.workOrderId);

export const selectSalesAgreementId = createSelector([selectAppDomain], (state: AppState) => state.salesAgreementId);

export const selectIsClosingOfferStillActive = createSelector(
  [selectAppDomain],
  (state: AppState) => state.isClosingOfferStillActive,
);

export const selectFulfillmentAgreementId = createSelector(
  [selectAppDomain],
  (state: AppState) => state.fulfillmentAgreementId,
);

export const selectErrorOverrideBody = createSelector(
  [selectAppDomain],
  (state: AppState) => state.errorModalOverrideBody,
);
export const selectErrorOverrideHeader = createSelector(
  [selectAppDomain],
  (state: AppState) => state.errorModalOverrideHeader,
);
export const selectErrorRetryAction = createSelector([selectAppDomain], (state: AppState) => state.errorRetryAction);
export const selectErrorOverrideButtonText = createSelector(
  [selectAppDomain],
  (state: AppState) => state.errorModalOverrideButtonText,
);
export const selectErrorOverrideSvg = createSelector(
  [selectAppDomain],
  (state: AppState) => state.errorModalOverrideSvg,
);
export const selectSalesFlowDefinition = createSelector(
  [selectAppDomain],
  (state: AppState) => state.salesFlowDefinition,
);
export const selectTimer = createSelector([selectAppDomain], (state: AppState) => state.timer);
export const selectShowOrderReview = createSelector([selectAppDomain], (state: AppState) => state.showOrderReview);
export const selectErrorModalAttempts = createSelector(
  [selectAppDomain],
  (state: AppState) => state.errorRetryAttempts,
);

export const selectDisplayCalculatedLaterCartLabel = createSelector(
  [selectAppDomain],
  (state: AppState) => state.displayCalculatedLaterCartLabel,
);

export const selectSmbLinkServiceabilityRedirect = createSelector(
  [selectAppDomain],
  (state: AppState) => state.smbLinkResidentialRedirect,
);
export const selectSmbLinkPlansRedirect = createSelector(
  [selectAppDomain],
  (state: AppState) => state.smbLinkPlansRedirect,
);
export const selectBuildVersion = createSelector([selectAppDomain], (state: AppState) => state.buildVersion);

export const selectIsStandAlone = createSelector([selectAppDomain], (state: AppState) => state.isStandAlone);

export const selectIsEmbedded = createSelector([selectAppDomain], (state: AppState) => state.isEmbedded);

export const selectHasVisitedOrderReview = createSelector(
  [selectAppDomain],
  (state: AppState) => state.hasVisitedOrderReview,
);

export const selectSkipSkeletonLoaderForContactInformation = createSelector(
  [selectAppDomain],
  (state: AppState) => !state.featureFlags.skeletonLoaderForContactInformation,
);

export const selectHideMfeCTA = createSelector([selectAppDomain], (state: AppState) => state.hideMfeCTA);
