import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Surface, Txt } from '@vst/beam';
import { RemoveCircleOutline } from '@vst/beam-icons/icons';

import { selectLocale, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';
import { priceDecimalFormat } from '@buy-viasat/utils';

import { CartProduct } from '@buy-viasat/types/build/bv';
import { CheckoutCartOptionalServicesProps } from '../CheckoutCartOptionalServices';

import styles from './checkoutCartOptionalServicesItem.module.scss';

const CheckoutCartOptionalServicesItem = (props: {
  addon: CartProduct;
  editMode: boolean;
  onRemove: () => void;
  translations: CheckoutCartOptionalServicesProps['translations'];
}) => {
  const { addon, editMode, onRemove, translations } = props;

  const locale = useSelector(selectLocale);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);

  const totalPrice = (addon.price || 0) + (addon.promo?.price || 0);

  const formattedPrice = priceDecimalFormat(addon.price || 0, locale, currencySymbol, 2);
  const formattedTotalPrice = priceDecimalFormat(totalPrice, locale, currencySymbol, 2);

  return (
    <Surface mt="8px" variant="secondary" className={styles['item']} data-testid="cartOptionalServicesItem">
      <Txt variant="bodySmallRegular">{addon.name}</Txt>
      <Surface variant="secondary" data-testid="cartOptionalServicesPrice" className={styles['price']}>
        <div className={styles['priceText']}>
          {totalPrice > 0 ? (
            <Txt variant="bodySmallRegular">{formattedTotalPrice}</Txt>
          ) : (
            <Txt variant="bodySmallBold" color="success" className={styles['free']}>
              {translations.free}
            </Txt>
          )}
          {addon.promo ? (
            addon.promo.duration ? (
              <Txt variant="tinyItalic" color="subtle">
                {translations.priceAfterPromotion(formattedPrice, addon.promo.duration.toString())}
              </Txt>
            ) : (
              <Txt variant="bodySmallRegular" color="subtle" className={styles['crossout']}>
                {formattedPrice}
              </Txt>
            )
          ) : null}
        </div>
        {editMode ? (
          <Icon size={16} color="blue_600" ml="12px" mt="4px" onClick={onRemove} icon={RemoveCircleOutline} />
        ) : null}
      </Surface>
    </Surface>
  );
};

export default CheckoutCartOptionalServicesItem;
