import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Surface, Txt, useBreakpoints, useToast } from '@vst/beam';

import {
  selectIsCafII,
  selectIsChoicePlan,
  selectIsDiamondPlan,
  selectIsUpsertPlanLoading,
  selectSelectedPlan,
} from '@buy-viasat/redux/src/plan';
import { selectEnv } from '@buy-viasat/redux/src/env';
import {
  selectCustomerType,
  selectFeatureFlags,
  selectIsModalVisible,
  selectIsVs2LargePlan,
  selectSalesFlowDefinition,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { selectCartItems } from '@buy-viasat/redux/src/cart';

import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { AemFeature, createAemUrl, topperColor } from '@buy-viasat/utils';
import { selectUpsertWithClosingOffersError } from '@buy-viasat/redux/src/closingOffers';

import ContentProvider from '../ContentProvider';
import { SelectedPlansName } from './SelectedPlansName';
import { CheckoutCartServiceDetails } from './CheckoutCartServiceDetails';
import { CheckoutCartDisplayServiceDetails } from './CheckoutCartDisplayServiceDetails';
import { CheckoutCartTotalMonthly } from './CheckoutCartTotalMonthly';
import { CheckoutCartDueToday } from './CheckoutCartDueToday';
import { CheckoutCartDescription } from './CheckoutCartDescription';
import { CheckoutCartTopper } from './CheckoutCartTopper';
import { CheckoutCartOptionalServices } from './CheckoutCartOptionalServices';
import { CheckoutCartNewPlansDescription } from './CheckoutCartNewPlansDescription';
import { addonItem, CheckoutCartProps, productFamilyAemPrefix, selectedAddons } from './types';
import styles from './checkoutCart.module.scss';

export const CheckoutCart = ({ displayMobileCart, phraseKeys }: CheckoutCartProps) => {
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectSelectedPlan);
  const { sellingPoint, topBarOptions, displayNewPlanCards } = useSelector(selectFeatureFlags);
  const { aemUrl } = useSelector(selectEnv);
  const isCafII = useSelector(selectIsCafII);
  const isVs2LargePlan = useSelector(selectIsVs2LargePlan);
  const isChoicePlan = useSelector(selectIsChoicePlan);
  const isDiamondPlan = useSelector(selectIsDiamondPlan);
  const cartItems = useSelector(selectCartItems);
  const isModalVisible = useSelector(selectIsModalVisible);
  const { shouldShowExtraTaxesDescriptionText } = useSelector(selectSalesFlowDefinition);

  const customerType = useSelector(selectCustomerType);
  const { characteristics, name } = selectedPlan ?? { characteristics: null, name: '' };
  const { bannerColor } = characteristics ?? {
    bannerColor: null,
  };

  const isUpsertPlanLoading = useSelector(selectIsUpsertPlanLoading);
  const isClosingOffersError: boolean = useSelector(selectUpsertWithClosingOffersError);

  const isBackgroundAPICallLoading: boolean = isUpsertPlanLoading || isClosingOffersError || isModalVisible;

  const shouldDisplaySelectedPlansName = sellingPoint === 'PlanName';
  const colorGradient = topBarOptions.split('_')[0].toLowerCase();
  const bannerColorGradient = bannerColor && styles[`${topperColor[bannerColor as keyof typeof topperColor]}Card`];
  const [displayServiceDetails, setDisplayServiceDetails] = useState(true);
  const onDisplayServiceDetailsClick = () => setDisplayServiceDetails(!displayServiceDetails);
  const isMobileCartOpened = displayMobileCart ? styles['cartOpened'] : styles['cartClosed'];

  const { addToast } = useToast();
  const { t } = useTranslation(['addons']);

  const { viewport } = useBreakpoints();
  const isMobile = viewport.smallerOrWithin.sm ?? false;
  const displayTopperColors = displayNewPlanCards ? `` : `${styles[`${colorGradient}Card`]} ${bannerColorGradient}`;

  // migrate calculations from children components
  const { enablingClosingOffersInBV } = useSelector(selectFeatureFlags);
  const { offerId: closingOfferIdURLParam } = useSelector(selectUrlParams);

  const { plan, closingOffers, addons } = cartItems || {};
  const { promo, leaseFeePrice, price } = plan || {};
  const checkForExistingClosingOffers = cartItems?.closingOffers?.find(
    (closingOffer) => closingOffer?.productTypeId === closingOfferIdURLParam,
  );
  const hasClosingOffers = !!checkForExistingClosingOffers?.price;
  const shouldDisplayClosingOffer = !!(closingOfferIdURLParam && enablingClosingOffersInBV && hasClosingOffers);

  const closingOffer =
    closingOffers?.find((closingOffer) => closingOffer?.productTypeId === closingOfferIdURLParam) || {};

  const selectedAddons = ((addons as Array<addonItem>) || []).reduce<selectedAddons>(
    (acc: selectedAddons, addonItem: addonItem) => {
      const { price, promo } = addonItem || {};
      return {
        ...acc,
        addonItems: [
          ...acc?.addonItems,
          {
            ...addonItem,
          },
        ],
        duration: acc.duration < promo?.duration ? promo?.duration : acc.duration,
        total: acc.total + (price || 0) + (promo?.price || 0),
        totalPriceWithoutPromotions: acc.totalPriceWithoutPromotions + -(promo?.price || 0),
      };
    },
    { addonItems: [], total: 0, totalPriceWithoutPromotions: 0, duration: 0 },
  );

  // service section
  const priceWithPromo = Number(price ?? 0) + Number(promo?.price ?? 0);
  const totalPrice = Number(priceWithPromo ?? 0) + Number(leaseFeePrice ?? 0);
  const totalPriceWithPromo = totalPrice + Number(closingOffer?.price ?? 0);
  const totalPriceWithoutPromotions = totalPrice + -Number(promo?.price ?? 0);
  // all cart prices
  const totalCart = totalPrice + Number(selectedAddons.total);
  const totalCartWithPromo = totalPriceWithPromo + Number(selectedAddons.total);
  const totalCartWithoutPromotions =
    totalPriceWithoutPromotions + Number(selectedAddons.total) + Number(selectedAddons.totalPriceWithoutPromotions);

  const calculationData = {
    cartItems,
    selectedAddons,
    shouldDisplayClosingOffer,
    priceClosingOffer: Number(closingOffer?.price ?? 0),
    durationClosingOffer: Number(closingOffer?.promo?.duration ?? 0),
    price: price ?? 0,
    priceWithPromo,
    totalPrice,
    totalPriceWithPromo,
    totalPriceWithoutPromotions,
    totalCart,
    totalCartWithPromo,
    totalCartWithoutPromotions,
    leaseFeePrice: Number(leaseFeePrice),
    isBackgroundAPICallLoading,
  };

  return (
    <Surface className={`${styles['cartContainer']} ${isMobileCartOpened}`} data-testid="cartContainer">
      <CheckoutCartTopper className={`${styles['cardPadding']} ${displayTopperColors}`}>
        <div data-testid="topSection">
          {shouldDisplaySelectedPlansName ? (
            <SelectedPlansName name={name} />
          ) : displayNewPlanCards ? (
            <CheckoutCartNewPlansDescription
              characteristics={characteristics}
              downloadSpeedPhraseKey={phraseKeys.header.speedDetails.below}
            />
          ) : (
            <CheckoutCartDescription
              characteristics={characteristics}
              sellingPoint={sellingPoint}
              headerPhraseKeys={phraseKeys.header}
            />
          )}
        </div>
        {!isMobile && (
          <CheckoutCartDisplayServiceDetails
            displayServiceDetails={displayServiceDetails}
            onClick={onDisplayServiceDetailsClick}
            showLabel={phraseKeys.serviceDetails.showLabel}
            hideLabel={phraseKeys.serviceDetails.hideLabel}
          />
        )}
        {displayServiceDetails && (
          <>
            <CheckoutCartServiceDetails name={name} data={calculationData} phraseKeys={phraseKeys.serviceDetails} />
            {(selectedAddons?.addonItems?.length && (
              <CheckoutCartOptionalServices
                translations={{
                  edit: phraseKeys.addons.edit,
                  free: phraseKeys.serviceDetails.free,
                  optionalServices: phraseKeys.serviceDetails.optionalServices,
                  priceAfterPromotion: phraseKeys.serviceDetails.priceAfterPromotion,
                }}
                addonItems={selectedAddons.addonItems}
                selectedAddonsTotal={selectedAddons?.total}
              />
            )) ||
              ''}
          </>
        )}
        <ContentProvider
          src={createAemUrl(
            aemUrl,
            i18n.language,
            AemFeature.CART_AD,
            productFamilyAemPrefix(isCafII, isChoicePlan, isDiamondPlan, isVs2LargePlan),
            customerType,
          )}
        />
        <CheckoutCartTotalMonthly data={calculationData} phraseKeys={phraseKeys.totalMonthly} />
        <CheckoutCartDueToday
          dueToday={phraseKeys.dueToday.title}
          totalDueToday={phraseKeys.dueToday.total}
          calculatedLaterLabel={phraseKeys.dueToday.calculatedLater}
          isBackgroundAPICallLoading={isBackgroundAPICallLoading}
        />
        {shouldShowExtraTaxesDescriptionText && <Txt variant="tinyRegular">{phraseKeys.extraTaxesDescriptionText}</Txt>}
      </CheckoutCartTopper>
    </Surface>
  );
};

export default CheckoutCart;
