import i18n from '@buy-viasat/plans/services/i18n';
import { AddonsState, selectAddonsDomain } from '@buy-viasat/redux/src/addons';
import {
  addressActions,
  AddressState,
  AddressType,
  defaultAddressData,
  LocationType,
  selectAddressDomain,
  selectAddressUrlParams,
  selectBillingAddressValues,
  selectBillingEqualsServiceAddress,
  selectScrubBillingAddress,
  selectScrubShippingAddress,
  selectShippingAddressValues,
  selectShippingEqualsServiceAddress,
} from '@buy-viasat/redux/src/address';
import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';
import {
  appActions,
  AppState,
  Modals,
  QueryParams,
  ScriptId,
  selectAppDomain,
  selectCountry,
  selectFeatureFlags,
  selectFeatureFlagsUserKey,
  selectHideMfeCTA,
  selectLocale,
  selectSalesFlowDefinition,
  selectSmbLinkPlansRedirect,
  selectSmbLinkServiceabilityRedirect,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import {
  authActions,
  isTokenReset,
  requestAccessToken,
  selectAccessToken,
  selectAccessTokenExpiration,
  selectFlowId,
} from '@buy-viasat/redux/src/auth';
import {
  CartState,
  selectCartDomain,
  selectOnetimeDiscount,
  selectOnetimeTotalWithoutPromo,
} from '@buy-viasat/redux/src/cart';
import { CreditVerificationState, selectCreditVerificationDomain } from '@buy-viasat/redux/src/credit-verification';
import {
  navActions,
  Routes,
  routeStates,
  selectIsMaxRouteGreaterThanCreditCheck,
} from '@buy-viasat/redux/src/navigator';
import {
  paymentInformationActions,
  PaymentInformationState,
  PaymentType,
  selectPaymentInformationDomain,
  selectVppPaymentType,
} from '@buy-viasat/redux/src/payment-information';
import { PersonalInformationState, selectPersonalInformationDomain } from '@buy-viasat/redux/src/personal-information';
import { selectPlanDomain, selectPreasignedPlanId } from '@buy-viasat/redux/src/plan';
import { PlanState } from '@buy-viasat/redux/src/plan/types';
import {
  ScheduleInstallationState,
  selectNoAvailableInstallationDates,
} from '@buy-viasat/redux/src/schedule-installation';
import {
  selectIsVermontCustomer,
  selectServiceabilityDomain,
  selectServiceAddressValues,
  serviceabilityActions,
} from '@buy-viasat/redux/src/serviceability';
import { ServiceabilityState } from '@buy-viasat/redux/src/serviceability/types';
import { subsidyProgramActions } from '@buy-viasat/redux/src/subsidy-program';
import { RESET_AUTH_ACTION, RESET_STATE_ACTION } from '@buy-viasat/redux/src/types';
import { Country, FeatureFlags } from '@buy-viasat/types/build/bv';
import { calculateDueTodayTotal, isDefined } from '@buy-viasat/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import env from 'env';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import {
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  retry,
  select,
  SelectEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import logUrlParamsAsync from 'shared/providers/requests/customer/logUrlParams';
import getFeatureFlags from 'shared/providers/requests/features';
import {
  setBillingAddressValid,
  setShippingAddressValid,
  validateAndCreateOrUpdateCustomer,
  validateShippingAddress,
} from 'shared/views/PersonalInformation/saga';
import { personalInformationActions } from 'shared/views/PersonalInformation/slice';
import { pullCityAndStateFromGeocodeResults } from 'shared/views/Serviceability/saga';
import { pixel } from '../../services/pixel';
import { getMillisFromMinutes } from '../../utils';
import clientLogger from '../../utils/clientLogger';
import { loadScript } from '../../utils/loadScript';
import { creditVerificationActions } from '../../views/CreditVerification/slice';
import { adobeTargetDataLayer, trackLoadTime } from '../Analytics';
import { DataLayerEvents } from '../Analytics/types';
import logUrlAsync from 'shared/providers/requests/customer/logUrl';
import { triggerSetCallingSaga } from './counterSaga';
import { TakeEffectApi } from 'redux-saga-test-plan/effects';
import logResetSessionAsync from 'shared/providers/requests/customer/logResetSession';
import { ClosingOffersState, selectClosingOffersDomain } from '@buy-viasat/redux/src/closingOffers';
import { validateClosingOfferCustomerID } from 'shared/providers/requests/customer/validateClosingOfferCustomerID';

const ONE_MINUTE_MILLIS = getMillisFromMinutes(1);
const ZERO_RESULTS = 'ZERO_RESULTS';

function* analyticsEventSaga(
  action: any,
): Generator<
  SelectEffect | CallEffect,
  void,
  AddonsState &
    ClosingOffersState &
    AppState &
    CartState &
    CreditVerificationState &
    PaymentInformationState &
    PersonalInformationState &
    PlanState &
    ScheduleInstallationState &
    ServiceabilityState &
    Routes &
    PaymentType
> {
  const app: AppState = yield select(selectAppDomain);
  const address: AddressState = yield select(selectAddressDomain);
  const cart: CartState = yield select(selectCartDomain);
  const creditVerification: CreditVerificationState = yield select(selectCreditVerificationDomain);
  const paymentInformation: PaymentInformationState = yield select(selectPaymentInformationDomain);
  const personalInformation: PersonalInformationState = yield select(selectPersonalInformationDomain);
  const plan: PlanState = yield select(selectPlanDomain);
  const serviceability: ServiceabilityState = yield select(selectServiceabilityDomain);
  const addons: AddonsState = yield select(selectAddonsDomain);
  const closingOffers: ClosingOffersState = yield select(selectClosingOffersDomain);
  const isGreaterThanCreditCheck = yield select(selectIsMaxRouteGreaterThanCreditCheck);
  const paymentType = yield select(selectVppPaymentType);
  const oneTimeDiscount = yield select(selectOnetimeDiscount);
  const onetimeTotalWithoutPromo = yield select(selectOnetimeTotalWithoutPromo);
  const locale = yield select(selectLocale);
  const noAvailableInstallationDates = yield select(selectNoAvailableInstallationDates);
  const featureFlags: FeatureFlags = yield select(selectFeatureFlags);
  const smbLinkResidentialRedirect = yield select(selectSmbLinkServiceabilityRedirect);
  const smbLinkPlansRedirect = yield select(selectSmbLinkPlansRedirect);
  const flowId = yield select(selectFlowId);

  try {
    pixel(
      'trackStructEvent',
      action.payload.category,
      action,
      action.payload.params?.label,
      action.payload.params?.property,
      action.payload.params?.value,
    );

    const analyticsPayload: any = {
      event: DataLayerEvents.AA_EVENT,
      eventName: 'analytics payload',
      trackingCategory: action.payload.category,
      trackingAction: action.payload.action,
      eventData: {
        event: 'analytics payload',
        addons: {
          availableAddons: [
            ...(addons.availableAddons?.addOns || []),
            ...(addons.availableAddons?.addOnGroup?.addOns || []),
          ],
        },
        closingOffers: {
          availableClosingOffers: closingOffers.availableClosingOffers,
        },
        app: {
          ...app,
          flowId,
          billingAddress: {
            postalCode: address.billingAddress.postalCode.value,
            countryCode: address.billingAddress.countryCode.value,
            region: address.billingAddress.region.value,
          },
          creditCheckResults: app.creditCheckResults,
          taxCodes: [
            {
              label: paymentInformation.taxCode.label,
              taxCodeType: paymentInformation.taxCode.taxCodeType,
              taxCodeValue: paymentInformation.taxCode.taxCodeValue,
            },
          ],
          scrubBillingAddress: {},
        },
        cart: {
          cartId: cart.cartId,
          promoTotal: cart.promoTotal,
          lease: cart.lease,
          monthlyTotalWithPromo: cart.monthlyTotalWithPromo,
          monthlyTotalWithoutPromo: cart.monthlyTotalWithoutPromo,
          onetimeTotalWithPromo: null,
          onetimeTotalWithoutPromo: null,
          monthlyTotal: null,
          onetimeTotal: null,
          onetimeDiscount: cart.onetimeDiscount,
        },
        creditVerification: {
          legalCheckbox: creditVerification.isLegalCheckboxChecked,
        },
        paymentInformation: {
          transactionId: paymentInformation.transactionId,
          spbBillingAccountId: paymentInformation.spbBillingAccountId,
        },
        planGrid: {
          smbPlansLinkSelected: false,
        },
        plan: {
          planId: plan.planId,
          planName: plan.planName,
          productFamily: plan.productFamily,
          isRegulated: plan.isRegulated,
        },
        plans: plan.availableProducts.map((p) => ({
          id: p.id,
          name: p.name,
          price: p.price,
        })),
        serviceability: {
          smbLinkSelected: false,
          serviceAddress: {
            region: serviceability.serviceAddress.region.value,
            postalCode: serviceability.serviceAddress.postalCode.value,
            countryCode: serviceability.serviceAddress.countryCode.value,
          },
        },
        scheduleInstallation: {
          noAvailableInstallationDates: noAvailableInstallationDates,
        },
        personalInformation: {
          subsidyApplicationId: personalInformation.subsidyApplicationId.value,
          isSubsidyCheckboxChecked: personalInformation.isSubsidyCheckboxChecked,
        },
        acpModal: {
          learnMoreLinkSelected: false,
          applyNowLinkSelected: false,
        },
        subsidyProgram: {
          acpTransferDiscount: null,
          tribalLandsDiscount: null,
          transferConsent: null,
          termsAndConditionsConsent: null,
          responseCode: '',
        },
        orderReview: {
          isOrderPlaced: app.isOrderSubmitting,
        },
      },
    };

    if (isGreaterThanCreditCheck) {
      const { dueTodayTotal } = calculateDueTodayTotal(
        onetimeTotalWithoutPromo,
        paymentType,
        oneTimeDiscount,
        locale,
        app.salesFlowDefinition.currencySymbol,
      );
      analyticsPayload.eventData.cart.onetimeTotalWithPromo = dueTodayTotal;
      analyticsPayload.eventData.cart.onetimeTotalWithoutPromo = cart.onetimeTotalWithoutPromo;

      analyticsPayload.eventData.cart.monthlyTotal = cart.monthlyTotalWithPromo;
      analyticsPayload.eventData.cart.onetimeTotal = cart.onetimeTotalWithPromo;
    }

    if (smbLinkResidentialRedirect) {
      analyticsPayload.eventData.serviceability.smbLinkSelected = true;
    }
    if (smbLinkPlansRedirect) {
      analyticsPayload.eventData.planGrid.smbPlansLinkSelected = true;
    }

    if (!featureFlags.showShippingAddress) {
      delete analyticsPayload.eventData.app.shippingAddress;
      delete analyticsPayload.eventData.app.shippingAddressEqualsServiceAddress;
    }

    adobeTargetDataLayer.push(analyticsPayload);
  } catch (err: any) {
    yield call(clientLogger, 'Error Occurred Updating Adobe Target: ', err);
  }
}

// Wrap a saga with this function to track load time in adobe
export function benchmark<P = void, T = unknown, TReturn = any, TNext = unknown>(
  saga: (action: PayloadAction<P>) => Generator<T, TReturn, TNext>,
): (action: PayloadAction<P>) => Generator<CallEffect | SelectEffect, void, unknown> {
  return function* (action: PayloadAction<P>) {
    const startTime: number = performance.now();
    yield call(saga, action);
    const sagaName = action.type;
    const flowId: unknown = yield select(selectFlowId);
    trackLoadTime(startTime, performance.now(), sagaName, flowId as string);
  };
}

export function* validateClosingOfferCustomerIDSaga(
  action: PayloadAction<string>,
): Generator<CallEffect | SelectEffect | PutEffect, void> {
  const {
    data: {
      validateClosingOfferCustomerID: { isValid },
    },
  }: any = yield call(validateClosingOfferCustomerID, action.payload);

  if (!isValid) {
    yield put(appActions.setOfferIdParam(''));
  }
  yield put(appActions.setIsClosingOfferStillActive(isValid));
}

export function* logURLParamsSaga(): Generator<CallEffect | SelectEffect | PutEffect, void> {
  const plnId: any = yield select(selectPreasignedPlanId);
  const hideCta: any = yield select(selectHideMfeCTA);
  const urlParamsInput: any = yield select(selectUrlParams);
  const addressInput: any = yield select(selectAddressUrlParams);

  const sanitizedInput = {
    hideCta,
    ...Object.fromEntries(
      Object.entries({ ...urlParamsInput, ...addressInput }).filter(([_, value]) => isDefined(value)),
    ),
  };

  if (Object.keys(sanitizedInput).length > 0) {
    yield call<any>(logUrlParamsAsync, isDefined(plnId) ? { ...sanitizedInput, plnId } : sanitizedInput);
  }
  yield put(addressActions.resetAddressQueryParams());
}

export function* logURLSaga(): Generator<CallEffect | SelectEffect, void> {
  const accessToken = yield select(selectAccessToken);
  if (!accessToken) return;
  yield call<any>(logUrlAsync, window?.location?.origin + window?.location?.pathname);
}

export function* resetSession(): Generator<
  SelectEffect | PutEffect | CallEffect | TakeEffectApi<any>,
  void,
  QueryParams
> {
  const { dealerId, partnerId, affiliateId, campaignId, sessionId } = yield select(selectUrlParams);

  yield put(RESET_STATE_ACTION);
  yield put(RESET_AUTH_ACTION);
  yield put(navActions.setRouteStates(routeStates));

  if (dealerId && partnerId) {
    yield put(
      navActions.onSessionReset({
        route: Routes.SERVICEABILITY,
        inputParams: { dealerId, partnerId, affiliateId, campaignId, sessionId },
      }),
    );
  }

  yield put(navActions.routeUserTo(Routes.SERVICEABILITY));
  yield put(paymentInformationActions.clearBillingData());

  yield put<any>(
    requestAccessToken({
      env: env.env,
      authUrl: env.serverUrls.auth,
      expiredSession: true,
    }),
  );
}

function* sessionTimeoutSaga(): Generator<SelectEffect | CallEffect | PutEffect, void, number> {
  while (true) {
    const sessionExpiration = yield select(selectAccessTokenExpiration);
    const flowId = yield select(selectFlowId);
    const selectIsTokenReset = yield select(isTokenReset);

    if (selectIsTokenReset) {
      yield put(authActions.setResetToken(false));
      logResetSessionAsync(`${flowId}`);
    }

    if (Date.now() + ONE_MINUTE_MILLIS > sessionExpiration) {
      yield call(resetSession);
      return; // kick us out of the generator until next session initialized
    }
    // Check if expired every minute
    yield delay(ONE_MINUTE_MILLIS);
  }
}

export function* setCountrySaga(action: PayloadAction<Country>): Generator<PutEffect, void, void> {
  yield put(serviceabilityActions.setServiceAddressCountryCode(action.payload));
}

export function* loadScriptSaga(
  action: PayloadAction<{ src: string; id: ScriptId }>,
): Generator<CallEffect | SelectEffect, void, HTMLElement & null & HTMLScriptElement> {
  try {
    yield retry(3, 10, loadScript, { src: action.payload.src, id: action.payload.id });
  } catch (err: any) {
    yield call(clientLogger, `error loading script: ${action.payload.id}`, err);
  }
}

export function* delayACPInfoVerificationSaga() {
  yield put(subsidyProgramActions.resetForm());
  const isVermontCustomer: boolean = yield select(selectIsVermontCustomer);
  if (isVermontCustomer) {
    yield put(creditVerificationActions.resetDob());
  }
  yield put(personalInformationActions.setSubsidyApplicationId(''));
  yield put(subsidyProgramActions.setACPDetailsLater(true));
}

export function* verifyACPInfoLaterSaga() {
  yield call(delayACPInfoVerificationSaga);
  yield put(navActions.next());
}

export function* onModalCancel(action: PayloadAction<Modals>) {
  const { geocodeSplitZipFormat } = yield select(selectSalesFlowDefinition);
  switch (action.payload) {
    case Modals.CONFIRM_SERVICE_ADDRESS:
      yield put(serviceabilityActions.useInlineServiceAddress());
      yield put(appActions.setIsModalVisible(false));
      break;
    case Modals.CONFIRM_BILLING_ADDRESS:
      yield put(personalInformationActions.setIsPersonalInformationLoading(true));
      const billingAddress: AddressType = yield select(selectBillingAddressValues);
      const billingPostalCode = billingAddress.postalCode.split('-')[0];
      try {
        const billingGeoCodeResults: google.maps.GeocoderResult[] = yield call<any>(
          geocodeByAddress,
          geocodeSplitZipFormat ? billingPostalCode : billingAddress.postalCode,
        );
        const { cityName: billingCity, stateName: billingState } = pullCityAndStateFromGeocodeResults(
          billingGeoCodeResults[0],
        );
        yield put(
          addressActions.setBillingAddress({
            ...billingAddress,
            addressLines: [
              billingAddress.addressLines[0].split(i18n.t('serviceability:common.address.appendApt'))[0],
              billingAddress.addressLines[1],
            ],
            municipality: billingCity,
            region: billingState,
          }),
        );
        yield call(setBillingAddressValid);
        yield call(validateShippingAddress);
        yield put(appActions.setIsModalVisible(false));
      } catch (err: any) {
        if (err === ZERO_RESULTS) {
          yield call(setBillingAddressValid);
          yield call(validateShippingAddress);
          yield put(appActions.setIsModalVisible(false));
        } else {
          yield put(
            appActions.setErrorRetryAction({
              type: appActions.onModalCancel.type,
              payload: null,
            }),
          );
          yield put(appActions.setModalVisible(Modals.ERROR));
          yield call(triggerSetCallingSaga, 'onModalCancel/CONFIRM_BILLING_ADDRESS');
        }
      }
      break;
    case Modals.CONFIRM_SHIPPING_ADDRESS:
      yield put(personalInformationActions.setIsPersonalInformationLoading(true));
      const shippingAddress: AddressType = yield select(selectShippingAddressValues);
      const shippingPostalCode = shippingAddress.postalCode.split('-')[0];
      try {
        const shippingGeoCodeResults: google.maps.GeocoderResult[] = yield call<any>(
          geocodeByAddress,
          geocodeSplitZipFormat ? shippingPostalCode : shippingAddress.postalCode,
        );
        const { cityName: shippingCity, stateName: shippingState } = pullCityAndStateFromGeocodeResults(
          shippingGeoCodeResults[0],
        );
        yield put(
          addressActions.setShippingAddress({
            ...shippingAddress,
            addressLines: [shippingAddress.addressLines[0].split(',')[0], shippingAddress.addressLines[1]],
            municipality: shippingCity,
            region: shippingState,
          }),
        );
        yield call(setShippingAddressValid);
        yield call(validateAndCreateOrUpdateCustomer);
        yield put(appActions.setIsModalVisible(false));
      } catch (err: any) {
        if (err === ZERO_RESULTS) {
          yield call(setShippingAddressValid);
          yield call(validateAndCreateOrUpdateCustomer);
          yield put(appActions.setIsModalVisible(false));
        } else {
          yield put(
            appActions.setErrorRetryAction({
              type: appActions.onModalCancel.type,
              payload: null,
            }),
          );
          yield put(appActions.setModalVisible(Modals.ERROR));
          yield call(triggerSetCallingSaga, 'onModalCancel/CONFIRM_SHIPPING_ADDRESS');
        }
      }
      break;
    case Modals.MANDATORY_SSN:
      yield put(
        creditVerificationActions.updateSsnMandatoryDetails({
          isSsnMandatory: true,
          displaySSNMandatoryModal: false,
        }),
      );
      yield put(appActions.setIsModalVisible(false));
      break;
  }
  yield put(appActions.setIsModalVisible(false));
}

export function* onModalConfirm(action: PayloadAction<Modals>) {
  switch (action.payload) {
    case Modals.CONFIRM_SERVICE_ADDRESS:
      yield put(serviceabilityActions.useSuggestedServiceAddress());
      break;
    case Modals.CONFIRM_BILLING_ADDRESS:
      const scrubBillingAddress: LocationType = yield select(selectScrubBillingAddress);
      yield put(addressActions.setBillingAddress(scrubBillingAddress.address));
      yield call(setBillingAddressValid);
      yield call(validateShippingAddress);
      yield put(appActions.setIsModalVisible(false));
      break;
    case Modals.CONFIRM_SHIPPING_ADDRESS:
      const scrubShippingAddress: LocationType = yield select(selectScrubShippingAddress);
      yield put(addressActions.setShippingAddress(scrubShippingAddress.address));
      yield call(setShippingAddressValid);
      yield call(validateAndCreateOrUpdateCustomer);
      yield put(appActions.setIsModalVisible(false));
      break;
    case Modals.NO_AVAILABLE_PLANS:
      yield put(navActions.previous());
      break;
    case Modals.AUTH_ERROR:
      yield put(navActions.routeUserTo(Routes.PAYMENT_INFORMATION));
      break;
    case Modals.CONFIRM_LATER:
      yield call(verifyACPInfoLaterSaga);
      break;
  }
  yield put(appActions.setIsModalVisible(false));
}

function* setBillingAddressEqualsServiceAddress(): Generator {
  const billingEqualServiceAddress: any = yield select(selectBillingEqualsServiceAddress);
  const serviceAddress: any = yield select(selectServiceAddressValues);
  const country: any = yield select(selectCountry);

  yield put(
    addressActions.setBillingAddress(billingEqualServiceAddress ? serviceAddress : defaultAddressData(country)),
  );
}

function* setShippingAddressEqualsServiceAddress(): Generator {
  const shippingEqualServiceAddress: any = yield select(selectShippingEqualsServiceAddress);
  const serviceAddress: any = yield select(selectServiceAddressValues);
  const country: any = yield select(selectCountry);

  yield put(
    addressActions.setShippingAddress(shippingEqualServiceAddress ? serviceAddress : defaultAddressData(country)),
  );
}

export function* getFeatureFlagsSaga() {
  try {
    yield put(appActions.generateFeatureFlagsUserKey());
    const userKey: string = yield select(selectFeatureFlagsUserKey);
    const { dealerId, partnerId, campaignId }: QueryParams = yield select(selectUrlParams);

    const isPartner = !!partnerId;
    const { data } = yield call(getFeatureFlags, userKey, isPartner, {
      dealerId,
      partnerId,
      campaignId,
    });

    yield put(appActions.loadedFeatureFlags(data?.getFeatureFlags ?? {}));
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.FEATURE_FLAGS_LOADED,
        action: TrackingActions.SUCCESSFUL,
        params: undefined,
      }),
    );
  } catch (err) {
    yield put(appActions.loadedFeatureFlags({}));
    clientLogger('unable to retrieve featureFlags', err);
  }
}

/** * Root saga manages watcher lifecycle
 */
export function* appSaga(): Generator<ForkEffect, void, void> {
  yield takeEvery(appActions.analyticsEvent.type, analyticsEventSaga);
  yield takeLatest(appActions.startSession.type, sessionTimeoutSaga);
  yield takeLatest(appActions.resetSession.type, resetSession);
  yield takeLatest(appActions.logURLParams.type, logURLParamsSaga);
  yield takeLatest(appActions.logURL.type, logURLSaga);
  yield takeLatest(appActions.setCountry.type, setCountrySaga);
  yield takeLatest(appActions.loadScript.type, loadScriptSaga);
  yield takeLatest(appActions.onModalCancel.type, onModalCancel);
  yield takeLatest(appActions.onModalConfirm.type, onModalConfirm);
  yield takeLatest(appActions.setFeatureFlags.type, getFeatureFlagsSaga);
  yield takeLatest(appActions.verifyACPInfoLater.type, verifyACPInfoLaterSaga);
  yield takeLatest(appActions.delayACPInfoVerification.type, delayACPInfoVerificationSaga);
  yield takeLatest(addressActions.setBillingEqualsServiceAddress.type, setBillingAddressEqualsServiceAddress);
  yield takeLatest(addressActions.setShippingEqualsServiceAddress.type, setShippingAddressEqualsServiceAddress);
  yield takeLatest(appActions.setClosingOfferCustomerID.type, validateClosingOfferCustomerIDSaga);
}
