import gql from 'graphql-tag';
import { ShoppingCart, ShoppingCartAddonsInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';
import { ShoppingCartFragment } from './fragments';
import { FetchResult } from '@apollo/client';

export type UpsertCartAddonsResponse = FetchResult<{ upsertCartAddons: ShoppingCart }>;

const UPSERT_CART_ADDONS = gql`
  mutation upsertCartAddons($input: ShoppingCartAddonsInput!) {
    upsertCartAddons(input: $input) {
      ...ShoppingCart
    }
  }
  ${ShoppingCartFragment}
`;

export default async function upsertCartAddonsAsync({
  input,
}: {
  input: ShoppingCartAddonsInput;
}): Promise<UpsertCartAddonsResponse> {
  return client.mutate({
    mutation: UPSERT_CART_ADDONS,
    variables: { input },
    fetchPolicy: 'no-cache',
  });
}
