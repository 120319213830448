import { CartItems, FeesAndDiscounts, Maybe } from '@buy-viasat/types/build/bv';

export interface CartState {
  cartId: Maybe<string>;
  cartItems: Maybe<CartItems>;
  promoTotal: number;
  lease: Maybe<number>;
  monthlyTotalWithPromo: Maybe<number>;
  monthlyTotalWithoutPromo: Maybe<number>;
  onetimeTotalWithPromo: Maybe<number>;
  onetimeTotalWithoutPromo: Maybe<number>;
  onetimeDiscount: Maybe<number>;
  isCartError: boolean;
  isCartAddonLoading: Maybe<string>;
  isCartClosingOfferLoading: Maybe<string>;
  oneTimeLeaseFee: Maybe<number>;
  leaseFeeShortName: Maybe<string>;
  optionalServicesEditMode: boolean;
  feesAndDiscounts?: FeesAndDiscounts[];
}

export const initialCartState: CartState = {
  cartId: null,
  cartItems: null,
  promoTotal: 0,
  lease: null,
  monthlyTotalWithPromo: null,
  monthlyTotalWithoutPromo: null,
  onetimeTotalWithPromo: null,
  onetimeTotalWithoutPromo: null,
  onetimeDiscount: null,
  isCartError: false,
  isCartAddonLoading: null,
  isCartClosingOfferLoading: null,
  oneTimeLeaseFee: null,
  leaseFeeShortName: null,
  optionalServicesEditMode: false,
  feesAndDiscounts: [],
};

export type ToastConfig = {
  translateKey?: string;
  translateOptions?: {};
  variant?: 'success';
};
