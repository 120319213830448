import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '../utils';
import { RESET_STATE_ACTION } from '../types';
import { AddOnPrices, AddonsState, addAddonsPayload, initialAddonsState } from './types';

const addonsSlice = createSlice({
  name: 'addons',
  initialState: initialAddonsState,
  reducers: {
    getAddons(state: AddonsState): void {
      state.isAddonsLoading = true;
    },
    addAddons(state: AddonsState, action: PayloadAction<addAddonsPayload>): void {},
    removeAddons(state: AddonsState, action: PayloadAction<{ id: string; price: number; promo: any }>): void {},
    setAvailableAddons(state: AddonsState, action: PayloadAction<AddonsState['availableAddons']>): void {
      state.isAddonsLoading = false;
      state.availableAddons = action.payload;
    },
    setIsAddonsError(state: AddonsState, action: PayloadAction<boolean>): void {
      state.isAddonsError = action.payload;
      state.isAddonsLoading = false;
    },
    setIsAddonsLoading(state: AddonsState, action: PayloadAction<boolean>): void {
      state.isAddonsLoading = action.payload;
    },
    setIsUpsertAddonsLoading(state: AddonsState, action: PayloadAction<boolean>): void {
      state.isUpsertAddonsLoading = action.payload;
    },
    toggleAddon(state: AddonsState, action: PayloadAction<{ id: string; selected: boolean }>): void {
      const { id, selected } = action.payload;

      const isGroupAddon = state.availableAddons?.addOnGroup?.addOns?.some((addon) => addon?.id === id);

      if (isGroupAddon) {
        state.availableAddons?.addOnGroup?.addOns?.forEach((addon) => {
          if (addon) {
            addon.selected = addon.id === id && selected;
          }
        });
      }

      state.availableAddons?.addOns?.forEach((addon) => {
        if (addon.id === id) {
          addon.selected = selected; // Toggle state for standalone add-ons
        }
      });
    },
    setSelectedAddonPrices(state: AddonsState, action: PayloadAction<AddOnPrices>): void {
      const addon = state.selectedAddonsPrices?.find((addon) => addon.id === action.payload.id);

      if (!addon) {
        state.selectedAddonsPrices.push(action.payload);
      }
    },
    setAddonIdForRemoval(state: AddonsState, action: PayloadAction<string>): void {
      state.addonIdToRemove = action.payload;
    },
    removeSelectedAddonPrices(state: AddonsState, action: PayloadAction<AddOnPrices>): void {
      const addon = state.selectedAddonsPrices?.filter((addon) => addon.id !== action.payload.id);

      if (addon) {
        state.selectedAddonsPrices = addon;
      }
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): AddonsState => initialAddonsState,
  },
});

export const { actions: addonActions, reducer: addonsReducer, name: addonKey } = addonsSlice;
