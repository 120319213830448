import { ApolloQueryResult, gql } from '@apollo/client';
import { AddOn, AddOnGroup, GetAddOnsInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetAddOnsPayload = ApolloQueryResult<{
  getAddOns: {
    addOns: AddOn[];
    addOnGroup: AddOnGroup;
  };
}>;

const ADD_ON_FIELDS = gql`
  fragment AddOnFields on AddOn {
    id
    name
    description
    tag
    kind
    price
    promo {
      price
      duration
      unit
    }
    selected
    attributes
    noAddAttributes
    shortName
    annualSavings
    crossOuts
    disclosure
    offerId
    displayOrder
    isGroupedProduct
  }
`;

export const GET_ADD_ONS_AND_GROUPS = gql`
  query getAddOnsAndAddOnGroups($input: GetAddOnsInput!) {
    getAddOns(input: $input) {
      addOns {
        ...AddOnFields
      }
      addOnGroup {
        id
        name
        description
        tag
        kind
        offerId
        addOns {
          ...AddOnFields
        }
      }
    }
  }
  ${ADD_ON_FIELDS}
`;

export default async function getAddonsAsync(input: GetAddOnsInput): Promise<GetAddOnsPayload> {
  return client.query({
    query: GET_ADD_ONS_AND_GROUPS,
    variables: { input },
    fetchPolicy: 'no-cache',
  });
}
