import { useSelector } from 'react-redux';

import { SkeletonLoader } from '../../SkeletonLoader';
import { Col, Container, Row, Surface, Txt } from '@vst/beam';
import { selectLocale, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';

import { priceDecimalFormat } from '@buy-viasat/utils';

import styles from './checkoutCartTotalMonthly.module.scss';
import PriceDetailPromo from '../PriceDetailPromo';
import { calculationData, CheckoutCartProps, selectedAddons } from '../types';
import { CartItems } from '@buy-viasat/types/build/bv';

export type CheckoutCartTotalMonthlyProps = {
  data: calculationData;
  phraseKeys: CheckoutCartProps['phraseKeys']['totalMonthly'];
};

const extractPromoDetails = (cartItems: CartItems | null, selectedAddons: selectedAddons) => {
  if (!cartItems) return {};

  const { promo: planPromo } = cartItems?.plan || {};

  return {
    planPromoDuration: planPromo?.duration ?? 0,
    addonPromoDuration: selectedAddons?.duration ?? 0,
    addonPrice: selectedAddons?.total ?? 0,
    addonPromoPrice: selectedAddons?.totalPriceWithoutPromotions ?? 0,
  };
};

export const CheckoutCartTotalMonthly = ({
  data: {
    cartItems,
    selectedAddons,
    durationClosingOffer,
    totalCartWithPromo,
    totalPrice,
    totalCart,
    totalCartWithoutPromotions,
    isBackgroundAPICallLoading,
  },
  phraseKeys,
}: CheckoutCartTotalMonthlyProps) => {
  const locale = useSelector(selectLocale);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);
  const { title, priceAfterPromotion, extraTaxesInfoText } = phraseKeys;
  const {
    planPromoDuration = 0,
    addonPromoDuration = 0,
    addonPrice = 0,
    addonPromoPrice = 0,
  } = cartItems ? extractPromoDetails(cartItems, selectedAddons) : {};
  const finalPriceWithoutPromos = addonPromoPrice ? totalPrice + addonPrice + addonPromoPrice : totalPrice;

  return (
    <Container fluidUntil={'md'} mt="24px" className={styles['container']} data-testid="totalMontlyContainer">
      <Row edge2edge>
        <Col className={styles['column']}>
          <Surface pb="16px">
            <Surface className={styles['heading']} data-testid="totalMonthlySurface">
              <Txt variant="heading6" data-testid="totalMonthlyText">
                {title}
              </Txt>
              <Txt variant="paragraphBold" data-testid="totalMonthlyValue">
                {isBackgroundAPICallLoading ? (
                  <SkeletonLoader characters={7} />
                ) : (
                  (priceDecimalFormat(totalCartWithPromo, locale, currencySymbol) ?? '--')
                )}
              </Txt>
            </Surface>
            <Surface className={styles['promotionDetails']} data-testid="promotionDetails">
              <Surface className={styles['promotionText']}>
                {isBackgroundAPICallLoading ? (
                  <Surface className={styles['skeletonSpacing']}>
                    <SkeletonLoader characters={12} />
                  </Surface>
                ) : (
                  <PriceDetailPromo
                    phraseKey={priceAfterPromotion ?? (() => '')}
                    durationClosingOffer={durationClosingOffer}
                    promoDuration={{ planPromoDuration, addonPromoDuration }}
                    totalPriceWithAddons={priceDecimalFormat(finalPriceWithoutPromos, locale, currencySymbol)}
                    totalPrice={priceDecimalFormat(totalCart, locale, currencySymbol)}
                    totalPriceWithoutPromotions={priceDecimalFormat(totalCartWithoutPromotions, locale, currencySymbol)}
                  />
                )}
              </Surface>
              <Txt
                variant="smallRegular"
                className={styles['promotionExtraDetails']}
                data-testid="promotionExtraDetails"
                color="subtle"
              >
                {isBackgroundAPICallLoading ? <SkeletonLoader characters={5} /> : extraTaxesInfoText}
              </Txt>
            </Surface>
          </Surface>
        </Col>
      </Row>
    </Container>
  );
};
