import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialPlanState } from './';
import { OfferNames, PlanState } from './types';

export const selectPlanDomain = (state: RootState): PlanState => state?.plan || initialPlanState;

export const selectAvailableProducts = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.availableProducts,
);
export const selectIsPlanLoading = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.isPlanLoading,
);
export const selectIsUpsertPlanLoading = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.isUpsertPlanLoading,
);
export const selectPlanId = createSelector([selectPlanDomain], (planState: PlanState) => planState.planId);

export const selectValidatedClosingOfferId = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.validatedClosingOfferId,
);

export const selectPlanOfferId = createSelector([selectPlanDomain], (planState: PlanState) => planState.offerId);

export const selectPlanName = createSelector([selectPlanDomain], (planState: PlanState) => planState.planName);

export const selectHasCafII = createSelector([selectPlanDomain], (planState: PlanState) => planState.hasCafII);

export const selectHasUnleashedPlan = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasUnleashedPlan,
);

export const selectHasUnlimitedPlans = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasUnlimitedPlans,
);

export const selectHasBusinessChoicePlans = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasBusinessChoicePlans,
);

export const selectHasChoicePlans = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasChoicePlans,
);

export const selectHasDiamondPlans = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasDiamondPlans,
);

export const selectIsCafII = createSelector([selectPlanDomain], (planState: PlanState) => planState.isCafII);

export const selectIsChoicePlan = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.productFamily === OfferNames.CHOICE,
);

export const selectIsDiamondPlan = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.planName?.split(' ')?.[1] === OfferNames.DIAMOND,
);

export const selectHasDiscoveryPlans = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.hasDiscoveryPlans,
);

export const selectInflectionPointText = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.inflectionPointText,
);

export const selectIsACPBannerShowing = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.isACPBannerShowing,
);

export const selectIsRegulated = createSelector([selectPlanDomain], (planState: PlanState) => planState.isRegulated);

export const selectProductFamily = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.productFamily,
);

export const selectViewDetailsPlan = createSelector([selectPlanDomain], (state: PlanState) => state.viewDetailsPlan);

export const selectDisplayingVoicePlans = createSelector(
  [selectPlanDomain],
  (state: PlanState) => state.displayingVoicePlans,
);

export const selectHasChoicePlusStream = createSelector(
  [selectPlanDomain],
  (state: PlanState) => state.hasChoicePlusStream,
);

export const selectHasImageOneUrl = createSelector([selectPlanDomain], (state: PlanState) => state.hasImageOneUrl);

export const selectHasNextGenPlans = createSelector([selectPlanDomain], (state: PlanState) => state.hasNextGenPlans);

export const selectHasNextGenCafIIPlans = createSelector(
  [selectPlanDomain],
  (state: PlanState) => state.hasNextGenCafIIPlans,
);

export const selectHasOnlyCafPlans = createSelector([selectPlanDomain], (state: PlanState) => state.hasOnlyCafPlans);

export const selectHasVS2LargePlans = createSelector([selectPlanDomain], (state: PlanState) => state.hasVS2LargePlans);

export const selectSelectedPlan = createSelector([selectPlanDomain], (state: PlanState) => state.selectedPlan);

export const selectIsLoading = createSelector([selectPlanDomain], (state: PlanState) => state.isLoading);

export const selectImageOneUrl = createSelector([selectPlanDomain], (state: PlanState) => state.imageOneUrl);

export const selectDataCap = createSelector([selectPlanDomain], (planState: PlanState) => planState.dataCap);

export const selectIsFragmentUnpublished = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.isFragmentUnpublished,
);

export const selectPreasignedPlanId = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.preassignedPlanId,
);

export const selectHavePlansLoaded = createSelector(
  [selectPlanDomain],
  (planState: PlanState) => planState.havePlansLoaded,
);
