import { ApolloQueryResult } from '@apollo/client';
import { CustomerParams, FeatureFlags } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from '../../../containers/apollo';

const FEATURE_FLAGS = gql`
  query getFeatureFlags($input: FeatureFlagsInput) {
    getFeatureFlags(input: $input) {
      singleFieldAddress
      allowChoosePlan
      allowChooseVoice
      serviceabilityAptSte
      scheduleInstallLocation
      onSiteContactInformation
      billingContactInformation
      displayAddonsPage
      isSSNMandatory
      authAmount
      pricePlanCard
      enableMarketingConsent
      showShippingAddress
      sellingPoint
      displayViewMoreDetailsLink
      topBarOptions
      displayPhoneNumber
      hideScrubAddressModal
      displayTooltipPlanGrid
      displayCreditCheckPage
      displayHouseNumber
      signupMyViasat
      displayRemarketingCheckbox
      displayNextSteps
      workOrderCharacterLimit
      dishNetworkPartnerInfo
      shouldDisplayDueToday
      displaySmbLink
      displayNewPlanCards
      maintenanceMode
      displayAcpBeforeCreditCheck
      enablePlaceMyOrderFromPaymentPage
      displayTotalSavingsTag
      shouldLogRaygunAnalytics
      skeletonLoaderForContactInformation
      enableIraNewEndpoint
      strictPhoneNumberValidation
      hideAcpCheckbox
      displayingTooltipOnBvPlanCards
      enablingClosingOffersInBV
      enableBroadbandLabels
      displayButtonLabels
      enableSubmitOrderPALTransition
      enablePalApis
      showBankAndCCPreauthMessage
      showGroupedEasyCare
    }
  }
`;

export default async function getFeatureFlags(
  userKey: string,
  isPartner: boolean,
  customerParams: CustomerParams,
): Promise<ApolloQueryResult<{ getFeatureFlags: FeatureFlags }>> {
  return client.query({
    query: FEATURE_FLAGS,
    variables: {
      input: {
        userKey,
        isPartner,
        customerParams,
      },
    },
    fetchPolicy: 'no-cache',
  });
}
