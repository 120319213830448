import { memo, useEffect, useRef, useState } from 'react';

import { Surface } from '@vst/beam';

import { useDispatch } from 'react-redux';
import { planActions } from '@buy-viasat/redux/src/plan';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import ErrorContent from '../ErrorContent/ErrorContent';

type ContentProviderProps = {
  src: string;
  onLoad?: () => void;
  id?: string;
  isLoading?: boolean;
  className?: string | undefined;
};

export const ContentProvider = (props: ContentProviderProps) => {
  const { src, onLoad, id, isLoading, className } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [loadingIsEasyCareComparison, setLoadingIsEasyCareComparison] = useState(false);
  const [showErrorContent, setShowErrorContent] = useState<boolean>(false);
  const isEasyCareComparison = src.includes('easycarecomparison');

  useEffect(() => {
    if (!src) return;

    if (isEasyCareComparison) {
      setLoadingIsEasyCareComparison(true);
    }

    const getContent = (res: Response): Promise<string> => {
      if (res.status === 200) {
        return res.text();
      } else {
        if (!isLoading) {
          dispatch(planActions.isFragmentUnpublished(id ?? ''));
        }
        const errorMessage = `AEM Error Status Code ${res.status}`;
        console.error(errorMessage);
        setShowErrorContent(true);
        throw new Error(errorMessage);
      }
    };

    const setContent = (htmlContent: string): void => {
      const slot = divRef?.current;
      if (!htmlContent || !slot) {
        return;
      }
      const fragment = document.createRange().createContextualFragment(htmlContent);
      slot.innerHTML = '';
      slot.appendChild(fragment);
    };

    fetch(src)
      .then(getContent)
      .then(setContent)
      .catch((error) => {
        console.error('Error in promise chain:', error);
        setShowErrorContent(true);
      })
      .finally(() => {
        if (isEasyCareComparison) {
          setLoadingIsEasyCareComparison(false);
        }
        if (onLoad) onLoad();
      });
  }, [src, dispatch, onLoad, isLoading, id, isEasyCareComparison]);

  return (
    <div id={id} className={className}>
      {isEasyCareComparison && loadingIsEasyCareComparison && (
        <Surface p={'32px'}>
          <LoadingSpinner size="large" />
        </Surface>
      )}
      {isEasyCareComparison && showErrorContent && <ErrorContent />}
      <div ref={divRef}></div>
    </div>
  );
};

export default memo(ContentProvider);
