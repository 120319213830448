import { FormEvent, FormEventHandler, useEffect, useMemo, useState } from 'react';

import NumericFormat from 'react-number-format';
import { HelperText, Icon, TextField, validators } from '@vst/beam';
import { Visibility, VisibilityOff } from '@vst/beam-icons/icons';

import styles from './taxIdInput.module.scss';

type TaxIdInputProps = {
  isInputObscured: boolean;
  regex: RegExp;
  validation?: { required?: string; invalid?: string };
  inputLabel: string;
  inputValue?: string;
  isRequired: boolean;
  placeholder: string;
  inputFormat: string;
  isAlphaNumeric?: boolean;
  hideStar?: boolean;
  onChange?: FormEventHandler<HTMLInputElement>;
};

type VisibilityToggleIconProps = {
  showInputValue: boolean;
  toggleVisibility: () => void;
  error: boolean;
};

const VisibilityToggleIcon = ({ showInputValue, toggleVisibility, error }: VisibilityToggleIconProps) => (
  <div className={styles['visibilityIcon']}>
    <Icon
      color={error ? 'alert_400' : 'inherit'}
      icon={showInputValue ? Visibility : VisibilityOff}
      data-testid="VisibilityIcon"
      onClick={toggleVisibility}
    />
  </div>
);

const validateInput = (inputValue: string | undefined, regex: RegExp, setError: (error: boolean) => void) => {
  if (inputValue) {
    if (!regex.test(inputValue)) {
      setError(true);
      return;
    }
    setError(false);
    return;
  }
  setError(false);
};

export const TaxIdInput = (props: TaxIdInputProps) => {
  const {
    isInputObscured,
    inputLabel,
    inputValue,
    inputFormat,
    isRequired,
    placeholder,
    regex,
    validation,
    isAlphaNumeric,
    hideStar,
    onChange,
  } = props;
  const [showInputValue, setShowInputValue] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }
    if (validation?.invalid) {
      validationRules.push(validators.pattern({ pattern: regex, message: validation.invalid }));
    }
    return validationRules;
  }, [validation, regex]);

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.toUpperCase();
    onChange?.(event);
  };

  const toggleVisibility = () => {
    setShowInputValue(!showInputValue);
  };

  useEffect(() => {
    validateInput(inputValue, regex, setError);
  }, [inputValue, regex, setError]);

  return isInputObscured ? (
    <>
      {/* This is a dummy input field to prevent autofill from filling the taxId field */}
      <input type="text" name="dummy-username" autoComplete="username" style={{ display: 'none' }} />

      <TextField
        name="taxId"
        fluid={true}
        labelProps={{ labelText: inputLabel, showStar: !hideStar }}
        placeholder={placeholder}
        required={isRequired}
        maxLength={4}
        value={showInputValue ? inputValue : inputValue?.replace(/./g, '•')}
        onChange={handleOnChange}
        data-testid="TaxIdInput"
        autoComplete={'off'}
        contentRight={
          <VisibilityToggleIcon showInputValue={showInputValue} toggleVisibility={toggleVisibility} error={error} />
        }
        className={error ? styles['taxIdErrorInput'] : ''}
      />
      {error && (
        <div className="mt-8">
          <HelperText state="error" helpMessage={validation?.invalid ?? ''} />
        </div>
      )}
    </>
  ) : isAlphaNumeric ? (
    <TextField
      name="taxId"
      required={isRequired}
      data-testid="TaxIdInput"
      validationRules={validationRules}
      placeholder={placeholder}
      value={inputValue}
      fluid={true}
      labelProps={{ labelText: inputLabel, showStar: !hideStar }}
      maxLength={inputFormat.length}
      onChange={handleOnChange}
      autoComplete={'off'}
    />
  ) : (
    <NumericFormat
      customInput={TextField}
      value={inputValue}
      fluid={true}
      format={inputFormat}
      mask="x"
      placeholder={placeholder}
      name="taxId"
      labelProps={{ labelText: inputLabel, showStar: !hideStar }}
      validationRules={validationRules}
      required={isRequired}
      data-testid="SSNInput"
      onChange={handleOnChange}
      autoComplete={'off'}
    />
  );
};
