import { useTranslation } from 'react-i18next';
import { IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import styles from './ErrorContent.module.scss';

const ErrorContent = (): JSX.Element => {
  const { t } = useTranslation('addons');
  return (
    <div className={styles['errorContainer']}>
      <IllustrativeIcon icon={Alert} color="warning_500" />
      <Txt variant="bodyLargeRegular" mt="24px" style={{ textAlign: 'center' }}>
        {t('addons.modal.error')}
      </Txt>
    </div>
  );
};

export default ErrorContent;
