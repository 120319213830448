import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { planActions } from '../plan';
import { COOKIE_NAME, Routes, ScheduleInstallLocations } from './types';
import { serviceabilityActions } from '../serviceability';
import { appActions } from '../app';
import { emptyOrRedacted, isDefined, isDefinedString, validateUrlParam } from '../utils';
import { getCookie } from '@buy-viasat/utils';
import { Country } from '@buy-viasat/types/build/bv';
import { addressActions } from '../address';

export const stepNumberFromRoute = (
  route: Routes,
  scheduleInstallLocation: ScheduleInstallLocations,
  isVermontCustomer: boolean,
  isSubsidyCheckboxChecked: boolean,
  hasAddons: boolean,
  displayCreditCheckPage: boolean,
  displayAcpBeforeCreditCheck: boolean,
): number => {
  let addonsStepNumber;
  let subsidyProgramStepNumber;
  let scheduleInstallStepNumber;
  let paymentInfoStepNumber;
  let orderReviewStepNumber;
  switch (route) {
    case Routes.PERSONAL_INFORMATION:
      return 1;

    case Routes.CREDIT_CHECK:
      if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked && !isVermontCustomer) return 3;
      return 2;

    case Routes.ADDONS:
      if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked) {
        return isVermontCustomer ? 3 : 4;
      }

      addonsStepNumber = 3;

      if (!displayCreditCheckPage) addonsStepNumber--;
      if (isVermontCustomer) addonsStepNumber--;
      return addonsStepNumber;

    case Routes.SUBSIDY_PROGRAM:
      if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked) return 2;
      subsidyProgramStepNumber = 4;

      if (!displayCreditCheckPage) subsidyProgramStepNumber--;
      if (!hasAddons) subsidyProgramStepNumber--;
      if (isVermontCustomer) subsidyProgramStepNumber--;
      return subsidyProgramStepNumber;

    case Routes.SCHEDULE_INSTALLATION:
      scheduleInstallStepNumber = 5;

      if (!displayCreditCheckPage) scheduleInstallStepNumber--;
      if (isVermontCustomer) scheduleInstallStepNumber--;
      if (!hasAddons) scheduleInstallStepNumber--;
      if (scheduleInstallLocation === ScheduleInstallLocations.Payments) scheduleInstallStepNumber++;
      if (!isSubsidyCheckboxChecked) scheduleInstallStepNumber--;
      return scheduleInstallStepNumber;

    case Routes.PAYMENT_INFORMATION:
      paymentInfoStepNumber = 6;

      if (!displayCreditCheckPage) paymentInfoStepNumber--;
      if (isVermontCustomer) paymentInfoStepNumber--;
      if (!hasAddons) paymentInfoStepNumber--;
      if (scheduleInstallLocation !== ScheduleInstallLocations.CreditCheck) paymentInfoStepNumber--;
      if (!isSubsidyCheckboxChecked) paymentInfoStepNumber--;
      return paymentInfoStepNumber;

    case Routes.ORDER_REVIEW:
      orderReviewStepNumber = 7;
      if (
        scheduleInstallLocation === ScheduleInstallLocations.OrderSummary ||
        scheduleInstallLocation === ScheduleInstallLocations.None
      )
        orderReviewStepNumber--;

      if (!displayCreditCheckPage) orderReviewStepNumber--;
      if (!hasAddons) orderReviewStepNumber--;
      if (isVermontCustomer) orderReviewStepNumber--;
      if (!isSubsidyCheckboxChecked) orderReviewStepNumber--;
      return orderReviewStepNumber;
    default:
      return 0;
  }
};

const parseQueryParams = (url: string) => {
  const searchParams = new URLSearchParams(url);

  return Array.from(searchParams).reduce(
    (acc: { [key: string]: string }, [currKey, currValue]: [string, string]) => ({
      ...acc,
      [currKey.toLowerCase()]: currValue || '',
    }),
    {},
  );
};

/* parseQueryString
 * Examines URL for predefined address input and other defined params. If minimum input criteria for scrubbing address is present (ie. addressLine1, postalCode, & countryCode),
 * then the address is scrubbed to check for address validity & routed to Plans if valid.
 * All other cases, (ie. no predefined address, invalid address, incomplete address, ect.) guarantee serviceability loader is false & do nothing. */
export const parseQueryString = (
  dispatch: Dispatch<PayloadAction<any>>,
  queryString: string,
  isServiceAddressSet: boolean,
  country?: Country,
): void => {
  const parsedParams = parseQueryParams(queryString);
  const {
    plnid: PLNID,
    affiliateid: affiliateID,
    sessionid: sessionID,
    cid: CID,
    offerid: offerId,
    cocid,
    region,
    municipality,
    ...restParams
  } = parsedParams;
  let {
    did: DID,
    paid: PAID,
    addressline1: addressLine1,
    addressline2: addressLine2,
    postalcode: postalCode,
    countrycode: countryCode,
    hidecta: hideCta,
  } = restParams;

  // Get the country code from state if countryCode isn't passed in
  if (!isDefinedString(countryCode)) countryCode = country as string;
  if (hideCta !== 'true') hideCta = '';

  if (!(DID || PAID)) {
    DID = getCookie(COOKIE_NAME.dealerId) as string;
    PAID = getCookie(COOKIE_NAME.partnerId) as string;
  }

  if (PLNID) {
    dispatch(planActions.setPreassignedPlanId(PLNID));
  }

  if (validateUrlParam(DID, 12)) {
    dispatch(appActions.setDealerIdParam(DID));
  }

  if (validateUrlParam(PAID, 40)) {
    dispatch(appActions.setPartnerIdParam(PAID));
  }

  if (validateUrlParam(affiliateID, 25)) {
    dispatch(appActions.setAffiliateIdParam(affiliateID));
  }

  if (validateUrlParam(sessionID, 40)) {
    dispatch(appActions.setSessionIdParam(sessionID));
  }

  if (validateUrlParam(CID, 25)) {
    dispatch(appActions.setCampaignIdParam(CID));
  }

  if (cocid) {
    dispatch(appActions.setClosingOfferCustomerID(cocid));
  }

  if (offerId) {
    dispatch(appActions.setOfferIdParam(offerId));
  }

  let isCompleteAddress = [addressLine1, postalCode, countryCode].every(isDefinedString);

  if (!isCompleteAddress) {
    addressLine1 = getCookie(COOKIE_NAME.addressLine1) as string;
    addressLine2 = !isDefined(addressLine2) ? (getCookie(COOKIE_NAME.addressLine2) as string) : addressLine2;
    postalCode = !isDefined(postalCode) ? (getCookie(COOKIE_NAME.postalCode) as string) : postalCode;
    isCompleteAddress = [addressLine1, postalCode, countryCode].every(isDefined);
    dispatch(serviceabilityActions.setIsServiceabilityLoading(false));
  }

  dispatch(
    addressActions.setAddressQueryParams({
      addressLine1: emptyOrRedacted(addressLine1),
      addressLine2: emptyOrRedacted(addressLine2),
      municipality,
      region,
      postalCode: emptyOrRedacted(postalCode),
      countryCode,
    }),
  );

  dispatch(appActions.setToggleMfeCTA(!!hideCta));
  dispatch(appActions.logURLParams());

  if (!isCompleteAddress) {
    return;
  }

  if (isServiceAddressSet) {
    return;
  }

  const serviceAddress = {
    addressLines: [addressLine1, addressLine2],
    municipality,
    region,
    postalCode,
    countryCode,
  };

  dispatch(serviceabilityActions.setServiceAddress(serviceAddress));
  dispatch(serviceabilityActions.scrubServiceAddress());
  dispatch(
    serviceabilityActions.updateScrubLocationAddressLine2(addressLine1 + addressLine2 ? ` ${addressLine2}` : ''),
  );
  dispatch(serviceabilityActions.isServiceAddressSet(true));
};
