import { v4 as uuid } from 'uuid';
import { Country } from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import { Persistor } from 'redux-persist';
import { adobeTargetDataLayer, COOKIE_NAME, getCookie, setCookie } from '@buy-viasat/utils';
import { DataLayerEventName, EventLogParams, TrackingActions, TrackingCategory } from '../analytics';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import { SalesFlowDefinition } from './SalesFlowDefinition';
import { Routes } from '../navigator';
import {
  AppState,
  CreditCheck,
  CustomerType,
  ErrorModalOverride,
  Features,
  initialAppState,
  Modals,
  ScriptId,
} from './types';

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    analyticsEvent(
      state: AppState,
      action: PayloadAction<{ category: TrackingCategory; action: TrackingActions; params?: EventLogParams }>,
    ) {},
    loadScript(state: AppState, action: PayloadAction<{ src: string; id: ScriptId }>): void {},
    loadedFeatureFlags(state: AppState, action: PayloadAction<Partial<Features>>): void {
      state.loadedFeatureFlags = true;
      state.featureFlags = { ...state.featureFlags, ...action.payload };
      const transform = { ...state.featureFlags, ...action.payload };
      Object.keys(transform).map((key: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        transform[key] = transform[key].toString();
      });
      adobeTargetDataLayer.push({
        event: DataLayerEventName.AA_EVENT,
        eventData: {
          event: 'featureFlagsLoaded',
          featureFlags: transform,
        },
      });
    },
    generateFeatureFlagsUserKey(state: AppState): void {
      let userKey: string | undefined = getCookie(COOKIE_NAME.userLDKey);
      if (!userKey) {
        userKey = uuid();
        setCookie(COOKIE_NAME.userLDKey, userKey, 365);
      }
      state.featureFlagsUserKey = userKey;
    },
    setErrorRetryAction(state: AppState, action: PayloadAction<{ type: string; payload: {} | [] | null }>): void {
      state.errorRetryAction = action.payload;
    },
    setFeatureFlags(): void {},
    setMarketPreferences(): void {},
    setCurrentRoute(state: AppState, action: PayloadAction<Routes>): void {
      state.currentRoute = action.payload;
    },
    scriptLoadedSuccessfully(state: AppState, action: PayloadAction<ScriptId>): void {},
    // need to pass the persistor to the saga through the payload
    startSession(state: AppState, action: PayloadAction<Persistor>) {},
    setBillingContactEqualsAccountHolder(state: AppState): void {
      state.billingContactEqualsAccountHolder = true;
    },
    setBillingContactNotEqualAccountHolder(state: AppState): void {
      state.billingContactEqualsAccountHolder = false;
    },
    setOnSiteContactEqualsAccountHolder(state: AppState): void {
      state.onSiteContactEqualsAccountHolder = true;
    },
    setOnSiteContactNotEqualAccountHolder(state: AppState): void {
      state.onSiteContactEqualsAccountHolder = false;
    },
    setCreditCheckResults(state: AppState, action: PayloadAction<CreditCheck[]>): void {
      state.creditCheckResults = action.payload;
    },
    setLocale(state: AppState, action: PayloadAction<string>): void {
      state.locale = action.payload;
    },
    setCustomerType(state: AppState, action: PayloadAction<CustomerType>): void {
      state.customerType = action.payload;
    },
    setCountry(state: AppState, action: PayloadAction<Country>): void {
      state.country = action.payload;
    },
    setIsAvailable(state: AppState, action: PayloadAction<boolean>): void {
      state.isAvailable = action.payload;
    },
    setIsCartVisible(state: AppState, action: PayloadAction<boolean>): void {
      state.isCartVisible = action.payload;
    },
    setIsCheckoutButtonDisabled(state: AppState, action: PayloadAction<boolean>): void {
      state.isCheckoutButtonDisabled = action.payload;
    },
    setIsModalVisible(state: AppState, action: PayloadAction<boolean>): void {
      state.isModalVisible = action.payload;
    },
    setIsOrderSubmitting(state: AppState, action: PayloadAction<boolean>): void {
      state.isOrderSubmitting = action.payload;
    },
    setIsPaymentInformationComplete(state: AppState, action: PayloadAction<boolean>): void {
      state.isPaymentInformationComplete = action.payload;
    },
    setIsPersonalInformationComplete(state: AppState, action: PayloadAction<boolean>): void {
      state.isPersonalInformationComplete = action.payload;
    },
    setIsScheduleInstallationComplete(state: AppState, action: PayloadAction<boolean>): void {
      state.isScheduleInstallationComplete = action.payload;
    },
    setIsTaxCodesLoading(state: AppState, action: PayloadAction<boolean>): void {
      state.isTaxCodesLoading = action.payload;
    },
    setLocation(state: AppState, action: PayloadAction<string | undefined>): void {
      state.location = action.payload;
    },
    setModalVisible(state: AppState, action: PayloadAction<Modals>): void {
      state.isModalVisible = true;
      state.currentModal = action.payload;
    },
    setTimerValue(state: AppState, action: PayloadAction<number>): void {
      state.timer = action.payload;
    },
    setModalVisibleOverride(state: AppState, action: PayloadAction<ErrorModalOverride>): void {
      state.isModalVisible = true;
      state.currentModal = action.payload.modal;
      state.errorModalOverrideBody = action.payload.overrideBody;
      state.errorModalOverrideHeader = action.payload.overrideHeader;
      state.errorModalOverrideButtonText = action.payload.overrideButtonText;
      state.errorModalOverrideSvg = action.payload.overrideSvg;
    },
    setPartyId(state: AppState, action: PayloadAction<string>): void {
      state.partyId = action.payload;
    },
    setOrderId(state: AppState, action: PayloadAction<string>): void {
      state.orderId = action.payload;
    },
    setRelnId(state: AppState, action: PayloadAction<string>): void {
      state.relnId = action.payload;
    },
    setPartyAndRelnId(
      state: AppState,
      { payload: { partyId, relnId } }: PayloadAction<{ partyId: string; relnId: string }>,
    ): void {
      state.partyId = partyId;
      state.relnId = relnId;
    },
    setOrganizationPartyId(state: AppState, action: PayloadAction<string>): void {
      state.organizationPartyId = action.payload;
    },
    setRedirected(state: AppState, action: PayloadAction<boolean>): void {
      state.redirected = action.payload;
    },
    setServiceabilityError(state: AppState, action: PayloadAction<boolean>): void {
      state.serviceabilityError = action.payload;
    },
    setServiceabilityMaybe(state: AppState, action: PayloadAction<boolean>): void {
      state.serviceabilityMaybe = action.payload;
    },
    setShowOrderReview(state: AppState, action: PayloadAction<boolean>): void {
      state.showOrderReview = action.payload;
    },
    setStartTime(state: AppState, action: PayloadAction<Date>): void {
      state.startTime = action.payload.toString();
    },
    toggleIsModalVisible(state: AppState): void {
      state.isModalVisible = !state.isModalVisible;
    },
    setExternalWorkOrderId(state: AppState, action: PayloadAction<string>): void {
      state.workOrderId = action.payload;
    },
    setHasSubmittedScheduleInstall(state: AppState, action: PayloadAction<boolean>): void {
      state.hasSubmittedScheduleInstall = action.payload;
    },
    setSalesAgreementId(state: AppState, action: PayloadAction<string>): void {
      state.salesAgreementId = action.payload;
    },
    setFulfillmentAgreementId(state: AppState, action: PayloadAction<string>): void {
      state.fulfillmentAgreementId = action.payload;
    },
    setSalesFlowDefinition(state: AppState, action: PayloadAction<SalesFlowDefinition>): void {
      state.salesFlowDefinition = action.payload;
    },
    setFeatureFlag(state: AppState, action: PayloadAction<Partial<Features>>): void {
      state.featureFlags = { ...state.featureFlags, ...action.payload };
    },
    setErrorModalAttempts(state: AppState, action: PayloadAction<number>) {
      state.errorRetryAttempts = action.payload;
    },
    setSmbLinkResidentialRedirect(state: AppState, action: PayloadAction<boolean>) {
      state.smbLinkResidentialRedirect = action.payload;
    },
    setSmbLinkPlansRedirect(state: AppState, action: PayloadAction<boolean>) {
      state.smbLinkPlansRedirect = action.payload;
    },
    verifyACPInfoLater() {},
    delayACPInfoVerification() {},
    setDealerIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.dealerId = action.payload;
    },
    setPartnerIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.partnerId = action.payload;
    },
    setAffiliateIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.affiliateId = action.payload;
    },
    setSessionIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.sessionId = action.payload;
    },
    setCampaignIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.campaignId = action.payload;
    },
    setOfferIdParam(state: AppState, action: PayloadAction<string>) {
      state.urlParams.offerId = action.payload;
    },
    setBuildVersionId(state: AppState, action: PayloadAction<string>): void {
      state.buildVersion.id = action.payload;
    },
    setBuildVersionUpdateRetryAttempts(state: AppState, action: PayloadAction<number>): void {
      state.buildVersion.updateRetryAttempts = action.payload;
    },
    setBuildVersionUpdateRequired(state: AppState, action: PayloadAction<boolean>): void {
      state.buildVersion.updateRequired = action.payload;
    },
    resetPartyId(state: AppState): void {
      state.partyId = '';
    },
    setIsEmbedded(state: AppState, action: PayloadAction<boolean>): void {
      state.isEmbedded = action.payload;
    },
    setIsStandAlone(state: AppState, action: PayloadAction<boolean>): void {
      state.isStandAlone = action.payload;
    },
    setHasVisitedOrderReview(state: AppState, action: PayloadAction<boolean>): void {
      state.hasVisitedOrderReview = action.payload;
    },
    setToggleMfeCTA(state: AppState, action: PayloadAction<boolean>): void {
      state.hideMfeCTA = action.payload;
    },
    setDisplayCalculatedLaterCartLabel(state: AppState, action: PayloadAction<boolean>): void {
      state.displayCalculatedLaterCartLabel = action.payload;
    },
    setClosingOfferCustomerID(state: AppState, action: PayloadAction<string>): void {
      state.closingOfferCustomerID = action.payload;
    },
    setIsClosingOfferStillActive(state: AppState, action: PayloadAction<boolean | undefined>): void {
      state.isClosingOfferStillActive = action.payload;
    },
    resetSession(state: AppState) {},
    logURLParams(state: AppState) {},
    logURL(state: AppState) {},
    // EVENT HANDLERS //
    onModalCancel(state: AppState, action: PayloadAction<Modals>): void {},
    onModalConfirm(state: AppState, action: PayloadAction<Modals>): void {},
    // EVENT HANDLERS END //
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (state: AppState): AppState => ({
      ...initialAppState,
      locale: state.locale,
      country: state.country,
      customerType: state.customerType,
      featureFlagsUserKey: state.featureFlagsUserKey,
    }),
  },
});

export const { actions: appActions, reducer: appReducer, name: appKey } = appSlice;
