import { ChangeEvent, FormEventHandler, useCallback, useMemo, useRef } from 'react';

import { dobRegex, DateFormat } from '@buy-viasat/utils';
import { TextField, validators, Surface, useBreakpoints, Spacer, HelperText } from '@vst/beam';

import styles from './dateOfBirthInput.module.scss';
import { DateOfBirthLabel } from './dateOfBirthLabel';
import { useDateOfBirthValidator } from './useDateOfBirthValidator';

export type DateOfBirthInputProps = {
  validation: {
    day: {
      required: string;
      invalid: string;
    };
    month: {
      required: string;
      invalid: string;
    };
    year: {
      required: string;
      invalid: string;
      min18: string;
    };
  };
  onDayChange?: FormEventHandler<HTMLInputElement>;
  onMonthChange?: FormEventHandler<HTMLInputElement>;
  onYearChange?: FormEventHandler<HTMLInputElement>;
  format?: DateFormat;
  labelText: string;
  tooltipText?: string;
  dayValue?: string;
  monthValue?: string;
  yearValue?: string;
  dayPlaceholder?: string;
  monthPlaceholder?: string;
  yearPlaceholder?: string;
};

export const DateOfBirthInput = ({
  dayValue,
  monthValue,
  yearValue,
  dayPlaceholder,
  monthPlaceholder,
  yearPlaceholder,
  validation,
  onDayChange,
  onMonthChange,
  onYearChange,
  format = DateFormat.MDY,
  labelText,
  tooltipText,
}: DateOfBirthInputProps) => {
  const { viewport } = useBreakpoints();
  const previousMonthValidValue = useRef('');
  const previousDayValidValue = useRef('');
  const previousYearValidValue = useRef('');

  const { error, validator, message: validatorMessage } = useDateOfBirthValidator(validation);

  const handleMonthChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const regex = dobRegex.month;
      const inputValue = event.currentTarget.value;

      if ((inputValue === '' || regex.test(inputValue)) && inputValue !== '00') {
        previousMonthValidValue.current = inputValue;
      } else if (!isNaN(parseInt(previousMonthValidValue.current, 10))) {
        event.currentTarget.value = previousMonthValidValue.current;
      } else {
        event.currentTarget.value = '';
      }

      onMonthChange?.(event);
    },
    [onMonthChange],
  );

  const handleDayChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const regex = dobRegex.day;
      const inputValue = event.currentTarget.value;

      if ((inputValue === '' || regex.test(inputValue)) && inputValue !== '00') {
        previousDayValidValue.current = inputValue;
      } else if (!isNaN(parseInt(previousDayValidValue.current, 10))) {
        event.currentTarget.value = previousDayValidValue.current;
      } else {
        event.currentTarget.value = '';
      }

      onDayChange?.(event);
    },
    [onDayChange],
  );

  const handleYearChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const regex = dobRegex.year;
      const inputValue = event.currentTarget.value;

      if (inputValue === '' || regex.test(inputValue)) {
        previousYearValidValue.current = inputValue;
      } else {
        event.currentTarget.value = previousYearValidValue.current;
      }

      onYearChange?.(event);
    },
    [onYearChange],
  );

  const monthAndDayFields = useMemo(
    () => [
      <TextField
        name="dob-month"
        key="dob-month"
        defaultValue={monthValue}
        validationRules={[validators.define(validator)]}
        placeholder={monthPlaceholder}
        fluid={true}
        onChange={handleMonthChange}
        data-testid="DOB-Month"
        error={error}
        autoComplete={'off'}
      />,
      !viewport.smallerOrWithin.xs && <Spacer x="16px" key="dob-spacer" />,
      <TextField
        name="dob-day"
        key="dob-day"
        defaultValue={dayValue}
        validationRules={[validators.define(validator)]}
        placeholder={dayPlaceholder}
        fluid={true}
        onChange={handleDayChange}
        data-testid="DOB-Day"
        error={error}
        autoComplete={'off'}
      />,
    ],
    [
      dayPlaceholder,
      dayValue,
      error,
      handleDayChange,
      handleMonthChange,
      monthPlaceholder,
      monthValue,
      validator,
      viewport,
    ],
  );

  return (
    <>
      <DateOfBirthLabel labelText={labelText} tooltipText={tooltipText} />
      <Surface className={styles['inputContainer']}>
        {format === DateFormat.DMY ? monthAndDayFields.reverse() : monthAndDayFields}
        {!viewport.smallerOrWithin.xs && <Spacer x="16px" />}
        <input type="text" name="dummy" autoComplete="username" style={{ display: 'none' }} />
        <TextField
          name="dob-year"
          key="dob-year"
          defaultValue={yearValue}
          validationRules={[validators.define(validator)]}
          placeholder={yearPlaceholder}
          fluid={true}
          onChange={handleYearChange}
          data-testid="DOB-Year"
          error={error}
          autoComplete={'off'}
        />
      </Surface>
      <div>
        {error && (
          <div className="mt-8">
            <HelperText state="error" helpMessage={validatorMessage} />
          </div>
        )}
      </div>
    </>
  );
};
