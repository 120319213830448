/*
 * Cart Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 */
import { CartItems, CartProduct, FeesAndDiscounts, Maybe, Promo, ShoppingCart } from '@buy-viasat/types/build/bv';
import { RESET_STATE_ACTION } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '../utils';
import { CartState, initialCartState } from './types';

export const precisionNumber = (value: number): number => {
  return parseFloat(value.toFixed(2));
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    upsertAddons(state: CartState): void {
      state.isCartError = false;
    },
    removeCartItems(state: CartState, action: PayloadAction<CartProduct>): void {
      state.isCartError = false;
    },

    // SETTERS Start
    removeAddOnFromCart(state: CartState, action: PayloadAction<string>): void {
      if (!state || !state.cartItems || !state.cartItems.addons) return;
      for (let i = 0; i < state.cartItems.addons.length; i++) {
        if (state.cartItems.addons[i]?.productTypeId === action.payload) {
          state.monthlyTotalWithPromo = precisionNumber(
            (state.monthlyTotalWithPromo ?? 0) - (state.cartItems.addons[i]?.price ?? 0),
          );
          state.monthlyTotalWithoutPromo = precisionNumber(
            (state.monthlyTotalWithoutPromo ?? 0) - (state.cartItems.addons[i]?.price ?? 0),
          );
          const promo = state.cartItems.addons[i]?.promo ?? null;
          if (promo && promo?.duration > 0) {
            state.monthlyTotalWithPromo = precisionNumber((state.monthlyTotalWithPromo ?? 0) - (promo.price ?? 0));
            state.monthlyTotalWithoutPromo = precisionNumber(
              (state.monthlyTotalWithoutPromo ?? 0) - (promo.price ?? 0),
            );
          }
          state.cartItems.addons.splice(i, 1);
          break;
        }
      }
    },
    setAddOnInCart(
      state: CartState,
      action: PayloadAction<{
        id: string;
        name: string;
        promo: Promo | any;
        price: Maybe<number> | undefined;
        categoryName: string;
        displayOrder?: number;
      }>,
    ): void {
      state.monthlyTotalWithPromo = precisionNumber((state.monthlyTotalWithPromo ?? 0) + (action.payload.price ?? 0));
      state.monthlyTotalWithoutPromo = precisionNumber(
        (state.monthlyTotalWithoutPromo ?? 0) + (action.payload.price ?? 0),
      );
      if (action.payload.promo?.duration > 0) {
        state.monthlyTotalWithPromo = precisionNumber(
          (state.monthlyTotalWithPromo ?? 0) + (action.payload.promo.price ?? 0),
        );
        state.monthlyTotalWithoutPromo = precisionNumber(
          (state.monthlyTotalWithoutPromo ?? 0) + (action.payload.promo.price ?? 0),
        );
      }
      state.cartItems?.addons?.push({
        productTypeId: action.payload.id,
        name: action.payload.name,
        price: action.payload.price,
        promo: action.payload.promo?.duration > 0 ? action.payload.promo : undefined,
        categoryName: action.payload.categoryName,
        displayOrder: action.payload.displayOrder,
      });
    },
    setCartId(state: CartState, action: PayloadAction<string>): void {
      state.cartId = action.payload;
    },
    setNewRootProductCandidateId(state: CartState, action: PayloadAction<string>): void {
      if (state?.cartItems?.plan?.productCandidateId) {
        state.cartItems.plan.productCandidateId = action.payload;
      }
    },
    setCartItems(state: CartState, action: PayloadAction<CartItems>): void {
      state.cartItems = action.payload;
    },
    setPromoTotal(state: CartState, action: PayloadAction<number>): void {
      state.promoTotal = action.payload;
    },
    setLease(state: CartState, action: PayloadAction<number>): void {
      state.lease = action.payload;
    },
    setOnetimeTotalWithoutPromo(state: CartState, action: PayloadAction<number>): void {
      state.onetimeTotalWithoutPromo = action.payload;
    },
    setOnetimeTotalWithPromo(state: CartState, action: PayloadAction<number>): void {
      state.onetimeTotalWithPromo = action.payload;
    },
    setOnetimeDiscount(state: CartState, action: PayloadAction<number>): void {
      state.onetimeDiscount = action.payload;
    },
    setIsCartError(state: CartState, action: PayloadAction<boolean>): void {
      state.isCartError = action.payload;
    },
    setIsCartAddonLoading(state: CartState, action: PayloadAction<string | null>): void {
      state.isCartAddonLoading = action.payload;
    },
    setIsCartClosingOfferLoading(state: CartState, action: PayloadAction<string | null>): void {
      state.isCartClosingOfferLoading = action.payload;
    },
    setCartProperties(state: CartState, action: PayloadAction<ShoppingCart>): void {
      state.cartId = action.payload.id;
      // We want to keep the marketing name from being overwritten by the name that’s coming from the cart.
      // And the way we do it is to update everything but the name.
      state.cartItems = {
        ...action.payload.cartItems,
        addons: action.payload.cartItems.addons.map((addon) => ({
          ...addon,
          name:
            state.cartItems?.addons.find((itemAddon) => itemAddon?.productTypeId === addon?.productTypeId)?.name ||
            addon?.name,
        })),
        closingOffers: action.payload.cartItems.closingOffers,
      };
      state.promoTotal = action.payload.prices.monthly.lineItems!.promo!;
      state.lease = action.payload.prices.monthly.lineItems!.lease!;
      state.monthlyTotalWithPromo = action.payload.prices.monthly.totalWithPromos!;
      state.monthlyTotalWithoutPromo = action.payload.prices.monthly.totalWithoutPromos!;
      state.onetimeTotalWithPromo = action.payload.prices.oneTime.totalWithPromos!;
      state.onetimeTotalWithoutPromo = action.payload.prices.oneTime.totalWithoutPromos!;
      state.onetimeDiscount = action.payload.prices.oneTime.lineItems!.promo!;
      state.oneTimeLeaseFee = action.payload.prices.oneTime.lease!;
      state.feesAndDiscounts = action.payload.prices.oneTime.feesAndDiscounts ?? [];
      state.leaseFeeShortName = action.payload.leaseFeeShortName;
    },
    setFeesAndDiscounts(state: CartState, action: PayloadAction<FeesAndDiscounts[]>): void {
      state.feesAndDiscounts = action.payload;
    },
    setOptionalServicesEditMode(state: CartState, action: PayloadAction<boolean>): void {
      state.optionalServicesEditMode = action.payload;
    },
    //  SETTERS END
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): CartState => initialCartState,
  },
});

export const { actions: cartActions, reducer: cartReducer, name: cartKey } = cartSlice;
