import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '@buy-viasat/redux/src/app';
import { selectAvailableAddons } from '@buy-viasat/redux/src/addons';

export const useHasAddons = () => {
  const { displayAddonsPage } = useSelector(selectFeatureFlags);
  const availableAddons = useSelector(selectAvailableAddons);

  return displayAddonsPage && (!!availableAddons?.addOns.length || !!availableAddons?.addOnGroup?.addOns?.length);
};

export default useHasAddons;
