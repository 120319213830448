import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';
import { ValidateClosingOfferCustomerIdPayload } from '@buy-viasat/types/build/bv';

const VALIDATE_CLOSING_OFFER_CUSTOMER_ID = gql`
  query validateClosingOfferCustomerID($input: ClosingOfferCustomerID!) {
    validateClosingOfferCustomerID(input: $input) {
      isValid
    }
  }
`;

export const validateClosingOfferCustomerID = async (
  closingOfferCustomerID: string,
): Promise<ValidateClosingOfferCustomerIdPayload> => {
  return client.query({
    query: VALIDATE_CLOSING_OFFER_CUSTOMER_ID,
    fetchPolicy: 'no-cache',
    variables: {
      input: { closingOfferCustomerID },
    },
  }) as ValidateClosingOfferCustomerIdPayload;
};
