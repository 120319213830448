import { selectSalesAgreementId, selectUrlParams } from '@buy-viasat/redux/src/app';
import { AddressType, Coordinates } from '@buy-viasat/redux/src/address';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AddOn, Promo } from '@buy-viasat/types/build/bv';
import { selectCoordinates, selectServiceAddressValues } from '@buy-viasat/redux/src/serviceability';
import { selectPlanId } from '@buy-viasat/redux/src/plan';
import { addonActions } from '@buy-viasat/redux/src/addons';
import getAddonsAsync from '../../providers/requests/products/getAddOns';
import clientLogger from '../../utils/clientLogger';
import { adobeTargetDataLayer } from 'shared/containers/Analytics';
import { DataLayerEvents } from 'shared/containers/Analytics/types';
import { benchmark } from 'shared/containers/App/saga';
import { cartActions } from '@buy-viasat/redux/src/cart';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getAddonsSaga() {
  const { dealerId, partnerId } = yield select(selectUrlParams);
  yield put(addonActions.setIsAddonsLoading(true));
  const planId: string = yield select(selectPlanId);
  const address: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const salesAgreementId: string = yield select(selectSalesAgreementId);
  try {
    const {
      data: { getAddOns },
    } = yield call(getAddonsAsync, {
      location: { address, coordinates },
      rootProductId: planId,
      dealerId,
      partnerId,
      salesAgreementId,
    });

    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      eventData: {
        event: 'addonView',
        addons: getAddOns.map((addon: AddOn) => ({ id: addon.id, name: addon.name })),
      },
    });

    yield put(addonActions.setAvailableAddons(getAddOns));

    yield put(addonActions.setIsAddonsLoading(false));
  } catch (err: any) {
    yield put(addonActions.setIsAddonsLoading(false));
    clientLogger('getAddonsSaga: ', err);
    yield put(addonActions.setIsAddonsError(true));
  }
}

export function* addAddonsSaga(
  action: PayloadAction<{
    id: string;
    price: number;
    promo: Promo;
    name: string;
    categoryName?: string;
    displayOrder?: number;
  }>,
) {
  yield put(
    addonActions.setSelectedAddonPrices({
      id: action.payload.id,
      addOnPrice: action.payload.price,
      addOnPromo: action.payload.promo?.price,
    }),
  );
  yield put(
    cartActions.setAddOnInCart({
      id: action.payload.id,
      name: action.payload.name,
      promo: action.payload.promo,
      price: action.payload.price,
      categoryName: action.payload.categoryName ?? '',
      displayOrder: action.payload.displayOrder,
    }),
  );
}

export function* removeAddonsSaga(action: PayloadAction<{ id: string; price: number; promo: Promo }>) {
  yield put(
    addonActions.removeSelectedAddonPrices({
      id: action.payload.id,
      addOnPrice: action.payload.price,
      addOnPromo: action.payload.promo?.price,
    }),
  );
  yield put(cartActions.removeAddOnFromCart(action.payload.id));
}

/**
 * Root saga manages watcher lifecycle
 */
export function* addonSaga(): Generator {
  yield takeLatest(addonActions.getAddons.type, benchmark(getAddonsSaga));
  yield takeLatest(addonActions.addAddons.type, benchmark(addAddonsSaga));
  yield takeLatest(addonActions.removeAddons.type, benchmark(removeAddonsSaga));
}
