import { createSelector } from 'reselect';
import { RootState } from '../types';
import { AddonsState, initialAddonsState } from './types';

export const selectAddonsDomain = (state: RootState): AddonsState => {
  return state?.addons || initialAddonsState;
};

export const selectAvailableAddons = createSelector(
  [selectAddonsDomain],
  (addonsState: AddonsState) => addonsState.availableAddons,
);

export const selectIsAddonSelected = createSelector([selectAvailableAddons], (availableAddons) => {
  const standaloneAddons = availableAddons?.addOns || [];
  const groupAddons = availableAddons?.addOnGroup?.addOns || [];

  return standaloneAddons.find((addon) => addon?.selected) || groupAddons.find((addon) => addon?.selected);
});

export const selectIsAddonsLoading = createSelector(
  [selectAddonsDomain],
  (addonsState: AddonsState) => addonsState.isAddonsLoading,
);
export const selectIsUpsertAddonsLoading = createSelector(
  [selectAddonsDomain],
  (addonsState: AddonsState) => addonsState.isUpsertAddonsLoading,
);
export const selectIsAddonsError = createSelector(
  [selectAddonsDomain],
  (addonsState: AddonsState) => addonsState.isAddonsError,
);

export const selectAddonMarkedForRemoval = createSelector([selectAddonsDomain], (addonsState: AddonsState) => {
  const standaloneAddons = addonsState?.availableAddons?.addOns || [];
  const groupAddons = addonsState?.availableAddons?.addOnGroup?.addOns || [];

  return (
    standaloneAddons.find((addon) => addon.id === addonsState.addonIdToRemove) ||
    groupAddons.find((addon) => addon?.id === addonsState.addonIdToRemove)
  );
});
