import { CartState } from '@buy-viasat/redux/src/cart';
import { AemProductFamily } from '@buy-viasat/utils';

export const productFamilyAemPrefix = (
  isCafII: boolean,
  isChoicePlans: boolean,
  isDiamondPlans: boolean,
  isVS2LargePlans: boolean,
) =>
  isCafII
    ? AemProductFamily.CAF
    : isChoicePlans
      ? AemProductFamily.CHOICE
      : isDiamondPlans
        ? AemProductFamily.DIAMOND
        : isVS2LargePlans
          ? AemProductFamily.VS2_LARGE
          : AemProductFamily.DEFAULT;

export type CheckoutCartProps = {
  displayMobileCart: boolean;
  phraseKeys: {
    header: {
      speedDetails: {
        above: string;
        below: string;
      };
      availableAddons: string;
    };
    serviceDetails: {
      hideLabel: string;
      showLabel: string;
      changeButton: string;
      planNameSuffix: string;
      free: string;
      categoryName: string;
      optionalServices: string;
      priceAfterPromotion: (price: string, duration: string) => string;
    };
    addons: {
      stream: string;
      voice: string;
      shield: string;
      easyCare: string;
      officeHours: string;
      edit: string;
      remove: string;
      priceAfterPromotion?: (price: string, duration: string) => string;
    };
    totalMonthly: {
      title: string;
      priceAfterPromotion?: (price: string, duration: string) => string;
      extraTaxesInfoText: string;
    };
    dueToday: {
      title: string;
      total: string;
      extraTaxesInfoText?: string;
      calculatedLater?: string;
    };
    mobile: {
      dueMonthlyTitle: string;
      dueTodayTitle: string;
    };
    extraTaxesDescriptionText: string;
  };
};

export type selectedAddons = {
  addonItems: Array<addonItem>;
  total: number;
  totalPriceWithoutPromotions: number;
  duration: number;
};
export type addonItem = {
  displayOrder: number;
  categoryName?: string;
  name?: string;
  productTypeId?: string;
  price?: number;
  promo?: any;
};

export type calculationData = {
  cartItems: CartState['cartItems'];
  selectedAddons: selectedAddons;
  shouldDisplayClosingOffer: boolean;
  priceClosingOffer: number;
  durationClosingOffer: number;
  price: number;
  priceWithPromo: number;
  promoDuration?: number;
  totalPrice: number;
  totalPriceWithPromo: number;
  totalPriceWithoutPromotions: number;
  totalCart: number;
  totalCartWithPromo: number;
  totalCartWithoutPromotions: number;
  isBackgroundAPICallLoading: boolean;
};

export interface DurationPromo {
  planPromoDuration?: number;
  addonPromoDuration?: number;
}
