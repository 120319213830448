import { selectAvailableAddons } from '@buy-viasat/redux/src/addons';
import {
  AddressType,
  Coordinates,
  selectBillingAddressValues,
  selectShippingAddressValues,
} from '@buy-viasat/redux/src/address';
import {
  appActions,
  CustomerType,
  Modals,
  QueryParams,
  selectCustomerType,
  selectFeatureFlags,
  selectFulfillmentAgreementId,
  selectHasVisitedOrderReview,
  selectLocale,
  selectOrderId,
  selectPartyId,
  selectRelnId,
  selectSalesAgreementId,
  selectSalesFlowDefinition,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { selectCartId } from '@buy-viasat/redux/src/cart';
import {
  selectDateOfBirth,
  selectDobDay,
  selectDobMonth,
  selectDobYear,
  selectTaxId,
} from '@buy-viasat/redux/src/credit-verification';
import {
  navActions,
  Routes,
  RouteStates,
  selectCurrentAppRoute,
  selectRoutesStates,
} from '@buy-viasat/redux/src/navigator';
import {
  paymentInformationActions,
  PaymentType,
  selectNonRegulatedSpbBillingAccountId,
  selectPaymentOnFileId,
  selectRegulatedSpbBillingAccountId,
  selectSmbBillingAccountId,
  selectSpbBillingAccountId,
  selectTransactionId,
  selectVppPPaymentType,
} from '@buy-viasat/redux/src/payment-information';
import {
  selectEmail,
  selectFirstName,
  selectIsSubsidyCheckboxChecked,
  selectLastName,
  selectPhone,
  selectSubsidyApplicationId,
  validatePersonalInformation,
} from '@buy-viasat/redux/src/personal-information';
import {
  scheduleInstallationActions,
  SelectedInstallation,
  selectSelectedInstallation,
} from '@buy-viasat/redux/src/schedule-installation';
import {
  selectCoordinates,
  selectIsVermontCustomer,
  selectServiceAddressRegion,
  selectServiceAddressValues,
} from '@buy-viasat/redux/src/serviceability';
import {
  selectIsFormValid,
  selectQualifiedForTribalLands,
  selectReceivingMonthlyDiscount,
  selectResponseView,
  selectTransferCheckboxChecked,
  subsidyProgramActions,
} from '@buy-viasat/redux/src/subsidy-program';
import {
  ErrorTypes,
  GetAddOnsPayload,
  NladBenefitsInput,
  PartyType,
  SubmitFullOrderInput,
  WorkOrderInformation,
} from '@buy-viasat/types/build/bv';
import {
  selectAccountHolderEmail,
  selectAccountHolderFirstName,
  selectAccountHolderLastName,
  selectOnSiteContactEmail,
  selectOnSiteContactFirstName,
  selectOnSiteContactLastName,
  selectOnSiteContactPhone,
} from '@buy-viasat/redux/src/business-information';
import { AcpResponseView, formatDate } from '@buy-viasat/utils';
import { ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit';

import { call, CallEffect, delay, fork, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { RouteActions, TransactionType } from 'shared/Navigator/types';
import { adobeTargetDataLayer } from 'shared/containers/Analytics';
import { DataLayerEvents } from 'shared/containers/Analytics/types';
import { benchmark } from 'shared/containers/App/saga';
import verifyNLADAsync from 'shared/providers/requests/customer/verifyNLAD';
import { v4 as uuid } from 'uuid';
import submitFullOrderAsync from '../providers/requests/order/submitFullOrder';
import { getBillingAccountIdToDeactivate } from '../utils';
import clientLogger from '../utils/clientLogger';
import history from '../utils/history';
import { DateOfBirth, FormFieldState } from '../views/Checkout/types';
import {
  selectCreditVerificationValidation,
  selectCreditVerificationValidationTaxId,
} from '../views/CreditVerification/selectors';
import { creditVerificationActions } from '../views/CreditVerification/slice';
import { selectSubsidyApplicationIdValid } from '../views/PersonalInformation/selectors';
import { personalInformationActions } from '../views/PersonalInformation/slice';
import { constructWorkOrderInformation, createRouteStates } from './utils';

export function* submitFullOrderError(err: any): Generator {
  clientLogger('submitFullOrderError: ', err);
  yield put(appActions.setIsOrderSubmitting(false));
  yield put(appActions.setIsCheckoutButtonDisabled(false));

  if (err.message === ErrorTypes.PaymentAuthError) {
    yield put(appActions.setModalVisible(Modals.AUTH_ERROR));
  } else {
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

// Handles pushes to history for routing.  Only used for direct routes such as edit buttons
function* onRouteUserToSaga(
  action: PayloadAction<Routes>,
): Generator<SelectEffect | PutEffect | CallEffect, void, string & PartyType> {
  const locale: string = yield select(selectLocale);
  const customerType: string = yield select(selectCustomerType);
  const urlPrefix = `/${locale}/${customerType}`;

  yield put(navActions.setMaxRoute(action.payload));
  yield put(navActions.setCurrentAppRoute(action.payload));
  yield call(history.push, `${urlPrefix}/${action.payload}`);
}

// Handles pushes to history for routing on Next, Previous or Reset to default route
export function onRouteSaga(routeAction: RouteActions) {
  return function* (): Generator<SelectEffect | PutEffect | CallEffect, void, string & PartyType & Routes> {
    const locale: string = yield select(selectLocale);
    const customerType: string = yield select(selectCustomerType);
    const urlPrefix = `/${locale}/${customerType}`;
    const currentRoute = yield select(selectCurrentAppRoute);
    const routeState: RouteStates = yield select(selectRoutesStates);
    let newRoute: Routes | undefined = undefined;
    switch (routeAction) {
      case RouteActions.Next:
        newRoute = routeState[currentRoute as Routes].Next;
        break;
      case RouteActions.Previous:
        newRoute = routeState[currentRoute as Routes].Previous;
        break;
      case RouteActions.Reset:
        newRoute = routeState[currentRoute as Routes].Reset;
        break;
    }

    if (newRoute === undefined) return;

    yield put(navActions.setMaxRoute(newRoute));
    yield put(navActions.setCurrentAppRoute(newRoute));
    yield call(history.push, `${urlPrefix}/${newRoute}`);
  };
}

function* setupAppRoutes() {
  const { scheduleInstallLocation, displayAddonsPage, displayCreditCheckPage } = yield select(selectFeatureFlags);
  const hasAddons: GetAddOnsPayload = yield select(selectAvailableAddons);
  const isVermontCustomer: boolean = yield select(selectIsVermontCustomer);
  const { displayAcpBeforeCreditCheck } = yield select(selectFeatureFlags);
  const isSubsidyCheckboxChecked: boolean = yield select(selectIsSubsidyCheckboxChecked);

  const shouldDisplayAddonsPage =
    displayAddonsPage && (!!hasAddons?.addOns.length || !!hasAddons?.addOnGroup?.addOns?.length);

  yield put(
    navActions.setRouteStates(
      createRouteStates(
        scheduleInstallLocation,
        isVermontCustomer,
        displayCreditCheckPage,
        shouldDisplayAddonsPage,
        isSubsidyCheckboxChecked,
        displayAcpBeforeCreditCheck,
      ),
    ),
  );
}

function* onCreditCheckButtonPress() {
  const {
    taxId: { isTaxId },
    dateFormat,
  } = yield select(selectSalesFlowDefinition);
  yield put(creditVerificationActions.validateCreditVerification({ dateFormat: dateFormat, isTaxId }));
  yield put(creditVerificationActions.validateDateOfBirth());

  const formValid: boolean = isTaxId
    ? yield select(selectCreditVerificationValidationTaxId)
    : yield select(selectCreditVerificationValidation);

  if (formValid) {
    yield put(creditVerificationActions.checkCustomerCredit());
  } else {
    yield put(appActions.setIsCheckoutButtonDisabled(false));
  }
}

export function* onOrderReviewButtonPress() {
  const relnId: string = yield select(selectRelnId);
  const cartId: string = yield select(selectCartId);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const billingAddress: AddressType = yield select(selectBillingAddressValues);
  const shippingAddress: AddressType = yield select(selectShippingAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const spbBillingAccountId: string = yield select(selectSpbBillingAccountId);
  const transactionId: string = yield select(selectTransactionId);
  const installationDate: FormFieldState<SelectedInstallation> = yield select(selectSelectedInstallation);
  const onSiteContactFirstName: string = yield select(selectOnSiteContactFirstName);
  const onSiteContactLastName: string = yield select(selectOnSiteContactLastName);
  const onSiteContactEmail: string = yield select(selectOnSiteContactEmail);
  const setOnSiteContactPhoneNumber: string = yield select(selectOnSiteContactPhone);
  const workOrderInformation: WorkOrderInformation | null = yield call(
    constructWorkOrderInformation,
    installationDate.value,
    {
      firstName: onSiteContactFirstName,
      lastName: onSiteContactLastName,
      email: onSiteContactEmail,
      phoneNumber: setOnSiteContactPhoneNumber,
    },
  );
  const taxId: FormFieldState<string> = yield select(selectTaxId);
  const paymentOnFileId: string = yield select(selectPaymentOnFileId);
  const urlParams: QueryParams = yield select(selectUrlParams);
  const paymentType: PaymentType = yield select(selectVppPPaymentType);
  const subsidyApplicationId: FormFieldState<string> = yield select(selectSubsidyApplicationId);
  const partyId: string = yield select(selectPartyId);
  const dob: DateOfBirth<string, string, string> = yield select(selectDateOfBirth);
  const salesAgreementId: string = yield select(selectSalesAgreementId);
  const fulfillmentAgreementId: string = yield select(selectFulfillmentAgreementId);
  const isReceivingMonthlyDiscount: boolean | null = yield select(selectReceivingMonthlyDiscount);
  const isTransferCheckboxChecked: boolean = yield select(selectTransferCheckboxChecked);
  const qualifiedForTribalLands: boolean | null = yield select(selectQualifiedForTribalLands);
  const isSubsidyCheckboxChecked: boolean = yield select(selectIsSubsidyCheckboxChecked);
  const responseView: string = yield select(selectResponseView);
  const regulatedSPBBillingAccountId: string = yield select(selectRegulatedSpbBillingAccountId);
  const nonRegulatedSPBBillingAccountId: string = yield select(selectNonRegulatedSpbBillingAccountId);
  const smbBillingAccountId: string = yield select(selectSmbBillingAccountId);
  const phoneNumber: FormFieldState<string> = yield select(selectPhone);
  const { shouldUseOneTimeTotalForVppTransaction } = yield select(selectSalesFlowDefinition);
  const customerType: CustomerType = yield select(selectCustomerType);

  let email = '';
  let firstName = '';
  let lastName = '';

  switch (customerType) {
    case CustomerType.BUSINESS:
      firstName = yield select(selectAccountHolderFirstName);
      lastName = yield select(selectAccountHolderLastName);
      email = yield select(selectAccountHolderEmail);
      break;
    case CustomerType.RESIDENTIAL:
      const firstNameField: FormFieldState<string> = yield select(selectFirstName);
      firstName = firstNameField.value;
      const lastNameField: FormFieldState<string> = yield select(selectLastName);
      lastName = lastNameField.value;
      const emailField: FormFieldState<string> = yield select(selectEmail);
      email = emailField.value;

      break;
  }

  let orderId: string = yield select(selectOrderId);
  if (!orderId.length) {
    orderId = uuid();
    yield put(appActions.setOrderId(orderId));
  }

  yield put(appActions.setIsOrderSubmitting(true));
  const deactivateBillingAccountArg = {
    nonRegulatedSPBBillingAccountId,
    smbBillingAccountId,
    regulatedSPBBillingAccountId,
    spbBillingAccountId,
  };
  const deactivateAccountIdList = getBillingAccountIdToDeactivate(deactivateBillingAccountArg);

  const input: SubmitFullOrderInput = {
    customerRelationshipId: relnId,
    cartId,
    billingAccountId: spbBillingAccountId,
    billingAccountIdToDeactivateList: deactivateAccountIdList,
    orderId,
    workOrderInformation,
    applicationId: subsidyApplicationId.value,
    partyId,
    payment: {
      transactionId,
      userAgent: navigator.userAgent,
      paymentOnFileId,
      paymentType,
    },
    customer: {
      taxId: taxId.value,
      serviceLocation: {
        address: serviceAddress,
        coordinates,
      },
      billingAddress,
      shippingAddress,
      firstName,
      lastName,
      dob: {
        month: dob.month.value,
        day: dob.day.value,
        year: dob.year.value,
      },
      phoneNumber: phoneNumber.value,
      email,
    },
    attributes: {
      affiliateId: urlParams.affiliateId,
      sessionId: urlParams.sessionId,
      campaignId: urlParams.campaignId,
      adobeEcId: adobeTargetDataLayer?.getState?.()?.userData?.ecid,
      dealerId: urlParams.dealerId,
      partnerId: urlParams.partnerId,
    },
    salesAgreementId,
    fulfillmentAgreementId,
  };

  if (shouldUseOneTimeTotalForVppTransaction) {
    input.transactionId = transactionId;
  }

  if (isSubsidyCheckboxChecked) {
    if (isReceivingMonthlyDiscount) {
      input.attributes = {
        ...input.attributes,
        transactionType: isReceivingMonthlyDiscount,
        transferConsent: isTransferCheckboxChecked,
        tribalBenefit: qualifiedForTribalLands,
        responseCode: responseView,
      };
    } else {
      input.attributes = {
        ...input.attributes,
        transactionType: isReceivingMonthlyDiscount,
        tribalBenefit: qualifiedForTribalLands,
        responseCode: responseView,
      };
    }
  }

  try {
    const { enablePlaceMyOrderFromPaymentPage } = yield select(selectFeatureFlags);
    const { shouldPlaceOrderFromPaymentPage } = yield select(selectSalesFlowDefinition);
    const placeOrderFromPaymentPage = enablePlaceMyOrderFromPaymentPage && shouldPlaceOrderFromPaymentPage;
    if (placeOrderFromPaymentPage) {
      yield put(appActions.setIsOrderSubmitting(true));
    }

    const { data = {} } = yield call(submitFullOrderAsync, input);
    yield put(appActions.setExternalWorkOrderId(data.submitFullOrder.externalWorkOrderId));
    if (placeOrderFromPaymentPage) {
      yield put(navActions.routeUserTo(Routes.ORDER_SUMMARY));
    } else {
      yield put(navActions.next());
    }
  } catch (err: any) {
    yield call(submitFullOrderError, err);
  }
}

export function* onPersonalInformationButtonPress() {
  const isSubsidyCheckboxChecked: boolean = yield select(selectIsSubsidyCheckboxChecked);
  const isVermontCustomer: boolean = yield select(selectIsVermontCustomer);

  // Be careful when moving async functions around in this saga because of loader

  const personalInfoValid: boolean = yield select(validatePersonalInformation);
  if (personalInfoValid) {
    yield put(appActions.setIsCheckoutButtonDisabled(false));
  } else {
    if (isSubsidyCheckboxChecked && isVermontCustomer) {
      yield put(creditVerificationActions.validateDateOfBirth());
    }

    yield put(personalInformationActions.validateBillingAddress());
  }
}

export function* checkScheduledInstallation() {
  yield put(appActions.setIsCheckoutButtonDisabled(false));
  const selectedInstallation: FormFieldState<SelectedInstallation> = yield select(selectSelectedInstallation);
  const hasVisitedOrderReview: boolean = yield select(selectHasVisitedOrderReview);
  const billingAccountId: string = yield select(selectSpbBillingAccountId);
  const currentRoute: Routes = yield select(selectCurrentAppRoute);
  const routeState: RouteStates = yield select(selectRoutesStates);
  const {
    value: { month, day, year, arrivalWindow },
  } = selectedInstallation;

  const dateIsSelected = Boolean(month && day && year);
  const timeIsSelected = arrivalWindow !== '';

  if (dateIsSelected === timeIsSelected) {
    yield put(appActions.setIsScheduleInstallationComplete(true));
    if (hasVisitedOrderReview && routeState[currentRoute as Routes].Next === Routes.PAYMENT_INFORMATION) {
      yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
      yield put(appActions.setHasVisitedOrderReview(false));
    }
    yield put(navActions.next());
  } else {
    yield put(scheduleInstallationActions.setHasSubmittedScheduleInstall(true));
  }
}

export function* onSubsidyProgramButtonPress() {
  const partyId: string = yield select(selectPartyId);
  const serviceAddressRegion: FormFieldState<string> = yield select(selectServiceAddressRegion);
  const dobMonth: FormFieldState<string> = yield select(selectDobMonth);
  const dobDay: FormFieldState<string> = yield select(selectDobDay);
  const dobYear: FormFieldState<string> = yield select(selectDobYear);
  const applicationId: FormFieldState<string> = yield select(selectSubsidyApplicationId);
  const isReceivingMonthlyDiscount: boolean | null = yield select(selectReceivingMonthlyDiscount);
  const qualifiedForTribalLands: boolean | null = yield select(selectQualifiedForTribalLands);

  yield put(subsidyProgramActions.validateForm());
  const isFormValid: boolean = yield select(selectIsFormValid);
  const isSubsidyApplicationIdValid: boolean = yield select(selectSubsidyApplicationIdValid);
  const { dateFormat } = yield select(selectSalesFlowDefinition);

  if (isFormValid && isSubsidyApplicationIdValid) {
    try {
      yield put(subsidyProgramActions.setIsVerifyButtonLoading(true));

      const input: NladBenefitsInput = {
        partyId: partyId,
        region: serviceAddressRegion.value,
        dateOfBirth: formatDate(dobMonth.value, dobDay.value, dobYear.value, dateFormat),
        applicationId: applicationId.value,
        transactionType: isReceivingMonthlyDiscount ? TransactionType.TRANSFER : TransactionType.ENROLL,
        tribalBenefit: qualifiedForTribalLands,
      };
      const { data } = yield call<any>(verifyNLADAsync, input);
      yield put(subsidyProgramActions.incrementVerifyAttempts());
      yield put(subsidyProgramActions.setResponseView(data.verifyNLAD.name));
      adobeTargetDataLayer.push({
        event: DataLayerEvents.AA_EVENT,
        eventData: {
          subsidyProgram: { responseCode: data.verifyNLAD.name },
        },
      });
      const responseView: string = yield select(selectResponseView);

      switch (responseView) {
        case AcpResponseView.APPLICATION_NOT_COMPLETE:
          yield put(appActions.setModalVisible(Modals.APPLICATION_NOT_COMPLETED));
          break;
        case AcpResponseView.NV_UNAVAILABLE:
        case AcpResponseView.SERVICE_UNAVAILABLE:
        case AcpResponseView.VIASAT_SERVICE_UNAVAILABLE:
          yield put(
            appActions.setErrorRetryAction({ type: navActions.onSubsidyProgramButtonPress.type, payload: null }),
          );
          yield put(appActions.setModalVisible(Modals.SYSTEM_DOWN_MODAL));
          break;
        case AcpResponseView.APPLICATION_NOT_FOUND:
          yield put(appActions.setModalVisible(Modals.ACP_APPLICATION_NOT_FOUND_MODAL));
          break;
        case AcpResponseView.APPLICATION_PENDING:
          yield put(appActions.setModalVisible(Modals.APPLICATION_PENDING));
          break;
        case AcpResponseView.DUPLICATE_SUBSCRIBER_NLAD:
          yield put(appActions.setModalVisible(Modals.DUPLICATE_SUBSCRIBER_NLAD));
          break;
        case AcpResponseView.NOT_ELIGIBLE_ACP:
          yield put(appActions.setModalVisible(Modals.NOT_ELIGIBLE_ACP));
          break;
        case AcpResponseView.SUCCESS:
          yield put(appActions.setModalVisible(Modals.ACP_SUCCESS_MODAL));
          break;
        case AcpResponseView.TRIBAL_BENEFIT_FLAG_DEFICIENT_CONSUMER_INFO:
          yield put(appActions.setModalVisible(Modals.DEFICIENT_CUSTOMER_INFO));
          break;
        case AcpResponseView.TRIBAL_BENEFIT_FLAG_NONTRIBAL_CONSUMER_LOCATION:
          yield put(appActions.setModalVisible(Modals.NONTRIBAL_CONSUMER_LOCATION));
          break;
        case AcpResponseView.TRIBAL_BENEFIT_FLAG_NONTRIBAL_NLAD_LOCATION:
          yield put(appActions.setModalVisible(Modals.NONTRIBAL_NLAD_LOCATION));
          break;
        default:
          yield put(appActions.setModalVisible(Modals.GENERAL_ERROR));
      }

      yield put(subsidyProgramActions.setIsVerifyButtonLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
    } catch (err) {
      yield put(subsidyProgramActions.setIsVerifyButtonLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
    }
  } else {
    yield put(subsidyProgramActions.setIsVerifyButtonLoading(false));
    yield put(appActions.setIsCheckoutButtonDisabled(false));
  }
}

function* handleCheckoutButtonPress(action: PayloadAction<Routes>) {
  switch (action.payload) {
    case Routes.PERSONAL_INFORMATION:
      yield call(onPersonalInformationButtonPress);
      break;
    case Routes.CREDIT_CHECK:
      yield call(onCreditCheckButtonPress);
      break;
    case Routes.SUBSIDY_PROGRAM:
      yield call(onSubsidyProgramButtonPress);
      break;
    case Routes.SCHEDULE_INSTALLATION:
      yield call(checkScheduledInstallation);
      break;
    case Routes.ORDER_REVIEW:
      yield call(onOrderReviewButtonPress);
      break;
  }
}

function* debounceOnRouteButtonPress(action: PayloadAction<Routes>) {
  yield put(appActions.setIsCheckoutButtonDisabled(true));
  yield delay(300);
  yield fork(handleCheckoutButtonPress, action);
}

// handle user edits of their information initiated from the CustomerLineItems
export function* handleEditButtonPress(action: ActionCreatorWithoutPayload) {
  const billingAccountId: string = yield select(selectSpbBillingAccountId);
  switch (action.type) {
    case navActions.onEditPaymentInformationPress.type:
      yield put(navActions.routeUserTo(Routes.PAYMENT_INFORMATION));
      yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
      break;
    case navActions.onEditPersonalInformationPress.type:
      yield put(navActions.routeUserTo(Routes.PERSONAL_INFORMATION));
      break;
    case navActions.onEditScheduleInstallationPress.type:
      yield put(navActions.routeUserTo(Routes.SCHEDULE_INSTALLATION));
      break;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* routerSaga(): Generator {
  // Direct routes
  yield takeLatest(navActions.routeUserTo.type, onRouteUserToSaga);

  // Checkout button presses
  yield takeLatest(navActions.onRouteButtonPress.type, benchmark<Routes>(debounceOnRouteButtonPress));

  // User information edit link handlers in the cart
  yield takeLatest(navActions.onEditPaymentInformationPress.type, handleEditButtonPress);
  yield takeLatest(navActions.onEditPersonalInformationPress.type, handleEditButtonPress);
  yield takeLatest(navActions.onEditScheduleInstallationPress.type, handleEditButtonPress);
  yield takeLatest(navActions.next.type, onRouteSaga(RouteActions.Next));
  yield takeLatest(navActions.previous.type, onRouteSaga(RouteActions.Previous));
  yield takeLatest(navActions.reset.type, onRouteSaga(RouteActions.Reset));
  yield takeLatest(navActions.setupAppRoutes.type, setupAppRoutes);
  yield takeLatest(navActions.onSubsidyProgramButtonPress.type, benchmark(onSubsidyProgramButtonPress));
}
