import { AddOn, AddOnGroup, ProductPromo } from '@buy-viasat/types/build/bv';

export enum ThunkActions {
  trackEvent = 'trackEvent',
  addAddons = 'addAddons',
  removeAddons = 'removeAddons',
}

export enum DataLayerEventType {
  ADDON_SELECTED = 'addonSelected',
}

export type GetAddOnsPayload = {
  addOns: AddOn[];
  addOnGroup: AddOnGroup;
};
export interface AddonsState {
  availableAddons: GetAddOnsPayload | null;
  isAddonsLoading: boolean;
  isUpsertAddonsLoading: boolean;
  isAddonsError: boolean;
  selectedAddonsPrices: AddOnPrices[];
  addonIdToRemove: string | null;
}

export type AddOnPrices = {
  id: string;
  addOnPrice: number;
  addOnPromo: number;
};

export const initialAddonsState: AddonsState = {
  availableAddons: null,
  isAddonsLoading: false,
  isUpsertAddonsLoading: false,
  isAddonsError: false,
  selectedAddonsPrices: [],
  addonIdToRemove: null,
};

export type addAddonsPayload = {
  id: string;
  name: string;
  price: number;
  promo: ProductPromo;
  categoryName: string;
  displayOrder?: number;
};
