import { createSelector } from 'reselect';
import { initialNavState, NavState, Routes } from './types';
import { RootState } from '../types';
import { stepNumberFromRoute } from './utils';
import { selectIsVermontCustomer } from '../serviceability';
import { selectIsSubsidyCheckboxChecked } from '../personal-information';
import { GetAddOnsPayload, selectAvailableAddons } from '../addons';

export const selectNavDomain = (state: RootState): NavState => {
  return state?.navigator || initialNavState;
};

export const selectCurrentAppRoute = createSelector([selectNavDomain], (state) => state.currentAppRoute);
export const selectRoutesStates = createSelector([selectNavDomain], (state) => state.routeStates);

export const selectMaxRoute = createSelector([selectNavDomain], (state: NavState) => state.maxRoute);

export const selectIsOrderSummary = createSelector(
  [selectNavDomain],
  (state: NavState) => state.currentAppRoute === Routes.ORDER_SUMMARY,
);
export const selectIsReview = createSelector(
  [selectNavDomain],
  (state: NavState) => state.currentAppRoute === Routes.ORDER_REVIEW,
);
export const selectIsStepOne = createSelector(
  [selectNavDomain],
  (state: NavState) => state.currentAppRoute === Routes.PERSONAL_INFORMATION,
);
export const selectIsMaxRouteGreaterThanScheduleInstallation = createSelector(
  [selectNavDomain],
  (state: NavState) => state.routeStates[state.maxRoute].order > state.routeStates[Routes.SCHEDULE_INSTALLATION].order,
);
export const selectIsMaxRouteGreaterThanCreditCheck = createSelector(
  [selectNavDomain],
  (state: NavState) => state.routeStates[state.maxRoute].order > state.routeStates[Routes.CREDIT_CHECK].order,
);
export const selectStepNumber = createSelector(
  [
    selectNavDomain,
    (state) => state.app,
    selectIsVermontCustomer,
    selectIsSubsidyCheckboxChecked,
    selectAvailableAddons,
  ],
  (
    state: NavState,
    appState,
    isVermontCustomer: boolean,
    isSubsidyCheckboxChecked: boolean,
    availableAddons: GetAddOnsPayload | null,
  ) =>
    stepNumberFromRoute(
      state.currentAppRoute,
      appState.featureFlags.scheduleInstallLocation,
      isVermontCustomer,
      isSubsidyCheckboxChecked,
      appState.featureFlags.displayAddonsPage && (!!availableAddons?.addOns.length ||
        !!availableAddons?.addOnGroup?.addOns?.length),
      appState.featureFlags.displayCreditCheckPage,
      appState.featureFlags.displayAcpBeforeCreditCheck,
    ),
);
